import axios from 'axios';
import { getTranslations } from 'helpers/translations';
import { NextApiRequest, NextApiResponse } from 'next';
import { Translate } from 'next-translate';
import { fetchAccessToken } from 'utils/fetchAccessToken';
import { intoCodename } from 'utils/normalization/careerFilters';

export const formatStates = (
  state: { [key: string]: number },
  translateFn: Translate,
  shouldUseDefaultLocale = false,
) => {
  const sortedState = state && sortObject(state);
  const newState =
    sortedState &&
    Object.entries(sortedState).map((item) => {
      return {
        apiCodename: item[0],
        name: translateFn(intoCodename(item[0]), null, {
          fallback: item[0],
          ...(shouldUseDefaultLocale && { ns: 'english' }),
        }),
        amountOfOffersWithTag: item[1],
      };
    });
  return newState;
};

const sortObject = (obj: { [key: string]: number }) =>
  Object.keys(obj)
    .sort()
    .reduce((res, key) => ((res[key] = obj[key]), res), {}) as { [key: string]: number };

const fetchJobsPosts = async (req: NextApiRequest, res: NextApiResponse) => {
  const fetchedAccessToken = await fetchAccessToken(process.env.JOBS_API_ACCESS_TOKEN_FETCH_SCOPE);

  const countries = req.query.countries as string;
  const workplace = req.query.workplace;
  const market = req.query.market;
  const careerStage = req.query.careerStage;
  const pageNumber = req.query.pageNumber;
  const search = req.query.search as string;

  const filterConditions = {
    ...(countries !== '' && { country: countries?.split('_SEPARATOR_') }),
    ...(workplace !== '' && { workplace: workplace }),
    ...(market !== '' && { market: market }),
    ...(careerStage !== '' && { careerStage: careerStage }),
    ...(search !== '' &&
      (search?.substring(0, 3).toLowerCase().includes('ref') ? { refNumber: search } : { title: search })),
  };

  const jobsPosts = await axios
    .get(`${process.env.JOBS_API_URL}?pageNumber=${pageNumber}&pageSize=10`, {
      data: {
        FilterConditions: {
          ...filterConditions,
        },
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${fetchedAccessToken.data.access_token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      res.status(500).json({ err });
      return;
    });
  const locale = (req.query.locale as string) || process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const {
    location: locationTranslate,
    workplace: workplaceTranslate,
    market: marketTranslate,
    career_stage: careerStageTranslate,
  } = await getTranslations(locale);

  jobsPosts.countries = formatStates(jobsPosts.countries, locationTranslate, locale === 'da-dk');
  jobsPosts.locations = formatStates(jobsPosts.locations, workplaceTranslate, locale === 'da-dk');
  jobsPosts.markets = formatStates(jobsPosts.markets, marketTranslate, locale === 'da-dk');
  jobsPosts.careerStages = formatStates(jobsPosts.careerStages, careerStageTranslate, locale === 'da-dk');

  res.status(200).json({ jobsPosts });
};

export default fetchJobsPosts;
