import { Box, Checkbox, Flex, FormLabel, Text } from '@chakra-ui/react';
import { toTitleCase } from 'helpers/textCase';
import { useRouter } from 'next/router';
import { useEffect, useId, useState } from 'react';
import { withTranslations } from 'store/translations';
import { intoCodename } from 'utils/normalization/careerFilters';
import { OptionsProps } from 'utils/overviewFunctionality';
import { shouldForceDefaultLocale } from 'utils/withForceLocale';

import { IconComp } from './IconComp';

interface InputProps {
  onWhite: boolean;
  label: string;
  placeholder: string;
  options: OptionsProps[];
  currentFilters?: string[];
  clearAll: () => void;
  onCheckFunc: (value: string) => void;
  onUnCheckFunc: (value: string) => void;
  disabled?: boolean;
  type?: 'email' | 'search' | 'text' | 'password';
}

// Currently only used for locations!
export const MultipleChoiceFilter = ({
  label,
  disabled,
  options,
  onCheckFunc,
  onUnCheckFunc,
  currentFilters,
  clearAll,
}: InputProps) => {
  const translations = withTranslations();
  const router = useRouter();
  const shouldUseDefaultLocale = shouldForceDefaultLocale(router);

  const id = useId();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const sortedOptions = options.sort(function (a, b) {
    const textA = a?.codename?.toUpperCase();
    const textB = b?.codename?.toUpperCase();
    return textA! < textB! ? -1 : textA! > textB! ? 1 : 0;
  });
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    if (isFilterOpen) {
      document.documentElement.classList.add('scroll-disable');
    } else {
      document.documentElement.classList.remove('scroll-disable');
    }
  }, [isFilterOpen]);
  return (
    <Box
      position='relative'
      display='inline-block'
      textStyle={'mobile.body.s'}
      sx={{
        ".inputBox_ramboll[aria-invalid='true'] + div": {
          display: 'inline-block',
        },
      }}
      pr={{ base: '0', lg: 'm' }}
      pt={{ base: 'xs', lg: '0' }}
      onMouseLeave={() => setIsFilterOpen(false)}
    >
      <FormLabel
        htmlFor={id}
        color={disabled ? 'mountain.60' : 'mountain.base'}
        margin='0'
        textStyle={'mobile.body.xs'}
        fontWeight='325'
        pb='5px'
      >
        {toTitleCase(translations.multiple(label, null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }))}
      </FormLabel>

      {/* -- Select button -- */}
      <Box
        as='button'
        aria-label={translations.aria(
          'openFilterForLabel',
          { label },
          { ...(shouldUseDefaultLocale && { ns: 'english' }) },
        )}
        id={id}
        position='relative'
        w={{ base: '100%', lg: '200px' }}
        h='48px'
        display='block'
        borderRadius='0'
        border='2px solid'
        borderColor='mountain.20'
        textAlign='left'
        background='gray.98'
        onClick={() => {
          setIsFilterOpen(true);
        }}
      >
        <Box pl='xs'>
          {/* CASE 1: There is no filter */}
          {!currentFilters?.[0] ? (
            translations.common('all', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })
          ) : currentFilters.length >= 2 ? (
            // CASE 2: There are more than 2 filters
            <Flex alignItems='center'>
              <Text maxWidth='65%' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden' pr='xxs'>
                {/* {
                  toTitleCase(
                    translations.location(currentFilters[0], null, {
                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                    }),
                  ) as string
                }{' '} */}
                {label == 'location'
                  ? toTitleCase(
                      translations.location(currentFilters[0], null, {
                        ...(shouldUseDefaultLocale && { ns: 'english' }),
                      }) as string,
                    )
                  : label == 'sector'
                  ? toTitleCase(
                      translations.sector(currentFilters[0], null, {
                        ...(shouldUseDefaultLocale && { ns: 'english' }),
                      }) as string,
                    )
                  : label == 'theme_topic'
                  ? toTitleCase(
                      translations.theme_topic(currentFilters[0], null, {
                        ...(shouldUseDefaultLocale && { ns: 'english' }),
                      }) as string,
                    )
                  : toTitleCase(
                      translations.location(currentFilters[0], null, {
                        ...(shouldUseDefaultLocale && { ns: 'english' }),
                      }) as string,
                    )}{' '}
              </Text>
              <Box
                display={'inline-block'}
                background='cyan.20'
                borderRadius='50%'
                w='30px'
                h='30px'
                textAlign={'center'}
                position='relative'
              >
                <Text position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)'>
                  +{currentFilters.length}
                </Text>
              </Box>
            </Flex>
          ) : (
            // CASE 3: There's only one filter
            <Text maxWidth='65%' whiteSpace='nowrap' textOverflow='ellipsis' overflow='hidden'>
              {/* {
                toTitleCase(
                  translations.location(currentFilters[0], null, {
                    ...(shouldUseDefaultLocale && { ns: 'english' }),
                  }),
                ) as string
              } */}
              {label == 'location'
                ? toTitleCase(
                    translations.location(currentFilters[0], null, {
                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                    }) as string,
                  )
                : label == 'sector'
                ? toTitleCase(
                    translations.sector(currentFilters[0], null, {
                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                    }) as string,
                  )
                : label == 'theme_topic'
                ? toTitleCase(
                    translations.theme_topic(currentFilters[0], null, {
                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                    }) as string,
                  )
                : toTitleCase(
                    translations.location(currentFilters[0], null, {
                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                    }) as string,
                  )}
            </Text>
          )}
          <Box display='inline-block' position='absolute' right='7px' top='10px'>
            <IconComp iconName='chevronDown' />
          </Box>
        </Box>
      </Box>
      {/* -- Select button End -- */}

      {/* -- Filter selection -- */}
      <Box
        position={{ base: 'fixed', lg: 'absolute' }}
        background='white'
        zIndex={9}
        display={{ base: 'unset', lg: isFilterOpen ? 'unset' : 'none' }}
        w={{ base: '100vw', lg: 'auto' }}
        h={{ base: 'calc(100dvh - 70px)', lg: 'auto' }}
        maxHeight={{ lg: '400px' }}
        overflowY={{ lg: 'scroll' }}
        bottom={{ base: isFilterOpen ? '0' : '-100vh', lg: 'unset' }}
        transition='bottom 0.5s'
        left={{ base: '0', lg: 'unset' }}
        borderTopRadius={{ base: '20px', lg: 'unset' }}
      >
        <Text display={{ base: 'block', lg: 'none' }} textAlign='center' textStyle='mobile.body.s' pt='xs'>
          {translations.common('filters', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
        </Text>
        <Box
          as='button'
          position='absolute'
          top='xs'
          right='xs'
          display={{ base: 'block', lg: 'none' }}
          onClick={() => {
            setIsFilterOpen(false);
          }}
        >
          <IconComp iconName='close' />
        </Box>
        {/* -- "All" selection (reset) -- */}
        <Box overflowY={{ base: 'scroll', lg: 'unset' }} h={{ base: '90%', lg: 'unset' }}>
          <Checkbox
            isChecked={currentFilters && !currentFilters[0]}
            display='block'
            onChange={() => {
              clearAll();
            }}
          >
            {'All'}
          </Checkbox>
          {/* -- "All" selection (reset) End -- */}

          {/* -- Filter options -- */}
          {sortedOptions.map((option: any, index: number) => {
            const translatedOption =
              label == 'location'
                ? (translations.location(option.codename, null, {
                    ...(shouldUseDefaultLocale && { ns: 'english' }),
                  }) as string)
                : label == 'sector'
                ? (translations.sector(option.codename, null, {
                    ...(shouldUseDefaultLocale && { ns: 'english' }),
                  }) as string)
                : label == 'theme_topic'
                ? (translations.theme_topic(option.codename, null, {
                    ...(shouldUseDefaultLocale && { ns: 'english' }),
                  }) as string)
                : (translations.location(option.codename, null, {
                    ...(shouldUseDefaultLocale && { ns: 'english' }),
                  }) as string);
            // For product, project, insights and news overview
            return option.amountOfPagesWithTag > 0 ? (
              <Checkbox
                key={index}
                isChecked={currentFilters && currentFilters.includes(option.codename)}
                display={'block'}
                onChange={(e) => (e.target.checked ? onCheckFunc(option.codename) : onUnCheckFunc(option.codename))}
              >
                {capitalizeFirstLetter(translatedOption)} {`(${option.amountOfPagesWithTag})`}
              </Checkbox>
            ) : // For career overview
            option.amountOfOffersWithTag! > 0 ? (
              <Checkbox
                key={index}
                isChecked={currentFilters && currentFilters.includes(intoCodename(option.apiCodename))}
                display={'block'}
                onChange={(e) => {
                  if (e.target.checked) {
                    onCheckFunc(option.apiCodename);
                  } else {
                    onUnCheckFunc(option.apiCodename);
                  }
                }}
              >
                {capitalizeFirstLetter(option.name)} {`(${option.amountOfOffersWithTag})`}
              </Checkbox>
            ) : null;
          })}
        </Box>
        {/* -- Filter options End -- */}
      </Box>
      {/* -- Filter selection End -- */}
    </Box>
  );
};
