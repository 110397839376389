import { ProdClient } from 'client/client';
import PeoplePage from 'components/pages/ContactUs/PeoplePage';
import cacheData from 'memory-cache';
import { Contact as ContactModel, ContactUsMetadata, Office as OfficeModel } from 'models';
import { useEffect } from 'react';
import { usePaginatedPeople } from 'store/paginated-people';
import { alphabeticalSort } from 'utils/alphabeticalSort';
import { isDefined } from 'utils/isDefined';
import getNormalizedGeneralOfficeData from 'utils/normalization/generalOffice';
import { getContactUsVariants } from 'utils/overviewVariants';
import { peopleCall } from 'utils/peopleCall';

const limit = 1000;
let skip = 0;
let namesData = [] as ContactModel[];
let filterData = [] as ContactModel[];

// Since we have a limit of 2001 Kontent items, we need to fethc all items, by setting limit and skip, with recursive call
async function fillNamesData(peopleCallFn: typeof peopleCall) {
  try {
    const response = await peopleCallFn(0)
      .limitParameter(limit)
      .skipParameter(skip)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise();
    if (
      !response.data.pagination.nextPage &&
      response.data?.pagination?.totalCount != null &&
      response.data?.pagination?.totalCount < skip
    ) {
      skip = 0;
      return;
    }

    namesData = namesData.concat(...response.data.items);
    skip += limit;
    await fillNamesData(peopleCallFn);
  } catch (e) {
    console.log('error', e);
  }
}

async function fillFilterData(languageCodename: string) {
  try {
    const response = await ProdClient.items<ContactModel>()
      .type('contact')
      .languageParameter(languageCodename)
      .includeTotalCountParameter()
      .elementsParameter(['department', 'city', 'market'])
      .limitParameter(limit)
      .skipParameter(skip)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise();
    if (!response.data.pagination.nextPage) {
      skip = 0;
      return;
    }
    filterData = filterData.concat(...response.data.items);
    skip += limit;
    await fillFilterData(languageCodename);
  } catch (e) {
    console.log('error', e);
  }
}

export const getStaticPeopleProps = async ({ params, locale }) => {
  const { page = 1, city, department, market } = params || {};
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  cacheData.put('languageCodename', languageCodename);
  const pageNum = isNaN(Number(page)) ? 1 : Number(page);

  const paginatedCall = peopleCall(pageNum, city, department, market);

  await fillNamesData(peopleCall);
  await fillFilterData(languageCodename);
  const [
    {
      data: { items: officesModel },
    },
    peopleData,
  ] = await Promise.all([
    ProdClient.items<OfficeModel>()
      .type('office')
      .languageParameter(languageCodename)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise(),
    paginatedCall.collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!).toPromise(),
  ]);

  const variants = await getContactUsVariants('peopleUrl');
  const names = namesData.map((item) => item.elements.displayName.value);

  const filterOptions = filterData
    .reduce<ContactModel[]>((acc, item) => {
      if (item.elements.city.value) {
        item.elements.city.value = item.elements.city.value.split(',')[0].trim();
      }
      if (item.elements.market.value) {
        item.elements.market.value = item.elements.market.value.split(',')[0].trim();
      }

      acc.push(item);
      return acc;
    }, [])
    .map((item) => item.elements);

  const contactUsMetaData = await ProdClient.items<ContactUsMetadata>()
    .type('contact_us_metadata')
    .languageParameter(languageCodename)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items[0];
    });

  const departmentOptions = new Array<string>();
  filterOptions.forEach(
    (item) =>
      !departmentOptions.includes(item.department.value.trim()) && departmentOptions.push(item.department.value.trim()),
  );

  const locationOptions = new Array<string>();
  filterOptions.forEach((item) => !locationOptions.includes(item.city.value) && locationOptions.push(item.city.value));

  const marketOptions = new Array<string>();
  filterOptions.forEach(
    (item) => !locationOptions.includes(item.market.value) && locationOptions.push(item.market.value),
  );

  const people = peopleData.data.items
    .filter(isDefined)
    .map((item) => item.elements)
    .sort((a, b) => alphabeticalSort(a.displayName.value, b.displayName.value, 0));

  return {
    variants,
    departmentOptions: departmentOptions.filter(Boolean).sort(),
    generalOffice: getNormalizedGeneralOfficeData(officesModel),
    locationOptions: locationOptions.filter(Boolean).sort(),
    marketOptions: marketOptions.filter(Boolean).sort(),
    people,
    pagination: peopleData.data.pagination,
    city: city ? city.charAt(0).toUpperCase() + city.slice(1) : '',
    department: department ? department : '',
    market: market ? market : '',
    contactUsMetaData: contactUsMetaData,
    names,
  };
};

const ContactUsPeoplePage = ({
  people,
  pagination,
  departmentOptions,
  locationOptions,
  marketOptions,
  city,
  market,
  department,
  contactUsMetaData,
  names,
  ...props
}: Awaited<ReturnType<typeof getStaticPeopleProps>>) => {
  const {
    setPeople,
    setPagination,
    setDepartments,
    setCities,
    setSelectedCity,
    setMarkets,
    setSelectedMarket,
    setSelectedDepartment,
    setNames,
  } = usePaginatedPeople();

  useEffect(() => {
    setPeople(people);
    setPagination(pagination);
    setDepartments(departmentOptions);
    setCities(locationOptions);
    setSelectedCity(city);
    setMarkets(marketOptions);
    setSelectedMarket(market);
    setSelectedDepartment(department);
    setNames(names);
  }, []);

  return <PeoplePage {...props} contactUsMetaData={contactUsMetaData} />;
};

export default ContactUsPeoplePage;
