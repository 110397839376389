import { Box, Text } from '@chakra-ui/react';
// import { Button } from 'components/base/Button';
import { useRouter } from 'next/router';
// import { shouldForceDefaultLocale } from 'utils/withForceLocale';
import React, { FunctionComponent } from 'react';
import { withTranslations } from 'store/translations';

export const JobAlert: FunctionComponent = () => {
  const { common } = withTranslations();
  const router = useRouter();

  return (
    <Box
      maxW={'780px'}
      p='xs'
      pb='xl'
      pt='xl'
      position={'relative'}
      left='50%'
      transform={'translateX(-50%)'}
      textAlign='center'
    >
      <Text as='h2' textStyle={{ base: 'mobile.h3', lg: 'desktop.h3' }} pb='s'>
        {common('jobAlert')}
      </Text>
      <Text textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }} pb='s'>
        {common('jobAlertText')}
      </Text>
      <div
        id='sr-alert-button'
        data-type='SIMPLE'
        style={router.locale == 'de-de' || router.locale == 'fi-fi' ? { marginLeft: '25%' } : { marginLeft: '37%' }}
      ></div>
    </Box>
  );
};
