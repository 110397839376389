//Since the data we get from JobsAPI is not using Kontent.ai codenames,
//we need to convert the data fetched to proper codenamed entities and run
//them through translations, but only on frontend side.
//Filters are still using fetched texts to fetch with filters

import slugify from 'slugify';

export const intoCodename = (text: string) => {
  return slugify(text, { trim: true, lower: true, replacement: '_' });
};
