import { Box, Container, Flex, Select, Text } from '@chakra-ui/react';
import { Button } from 'components/base/Button';
import { JobAlert } from 'components/modules/JobAlert';
import { TalentCommunity } from 'models/content-types/talent_community';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useState } from 'react';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { shouldForceDefaultLocale } from 'utils/withForceLocale';

interface TalentCommunitiesCTAProps {
  options: TalentCommunity[];
}

export const TalentCommunitiesCTA: FunctionComponent<TalentCommunitiesCTAProps> = ({ options }) => {
  const { common } = withTranslations();
  const [talentCommunity, setTalentCommunity] = useState<string>();
  const router = useRouter();
  const shouldUseDefaultLocale = shouldForceDefaultLocale(router);
  return (
    <>
      <Container w='100vw' bg='white' maxWidth={moduleMaxWidth}>
        <JobAlert />
      </Container>
      <Container w='100vw' bg='cyan.10' maxWidth={moduleMaxWidth}>
        <Box
          maxW={'780px'}
          p='xs'
          pb='xl'
          pt='xl'
          position={'relative'}
          left='50%'
          transform={'translateX(-50%)'}
          textAlign='center'
        >
          <Text as='h2' textStyle={{ base: 'mobile.h3', lg: 'desktop.h3' }} pb='s'>
            {common('joinOurTalentCommunity', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
          </Text>
          <Text textStyle={{ base: 'mobile.body.s', lg: 'desktop.body.l' }} pb='s'>
            {common('talentCommunityText', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
          </Text>
          <Flex
            align={'center'}
            justifyContent='center'
            flexDirection={{ base: 'column', lg: 'row' }}
            gap={{ base: '20px', lg: 'unset' }}
          >
            <Box pr={{ base: '0', lg: 'm' }} pt={{ base: 'xs', lg: '0' }}>
              <Select
                value={talentCommunity || ''}
                placeholder={common('selectTalentCommunity', null, {
                  ...(shouldUseDefaultLocale && { ns: 'english' }),
                })}
                _placeholder={{
                  color: 'mountain.60',
                  transition: 'color 0.2s',
                }}
                background={'gray.98'}
                position='relative'
                textStyle='desktop.body.book.s'
                w={'280px'}
                h={'60px'}
                display='block'
                borderRadius='0'
                border='2px solid'
                borderColor='mountain.20'
                onChange={(e) => setTalentCommunity(e.target.value)}
              >
                {options.map((tc, index) => {
                  return (
                    <option key={index} value={tc.elements.url.value}>
                      {tc.elements.name.value}
                    </option>
                  );
                })}
              </Select>
            </Box>
            <Button as='a' href={talentCommunity} isDisabled={!talentCommunity} size='medium' width='280px'>
              {common('joinOurTalentCommunity', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
            </Button>
          </Flex>
        </Box>
      </Container>
    </>
  );
};
