import { Box, FormLabel, Select } from '@chakra-ui/react';
import { toTitleCase } from 'helpers/textCase';
import { useRouter } from 'next/router';
import { useId } from 'react';
import { withTranslations } from 'store/translations';
import { OptionsProps } from 'utils/overviewFunctionality';
import { shouldForceDefaultLocale } from 'utils/withForceLocale';

interface InputProps {
  onWhite: boolean;
  label?: string;
  placeholder: string;
  disabled?: boolean;
  type?: 'email' | 'search' | 'text' | 'password';
  options: OptionsProps[] | string[];
  onChangeFunc: (value: string) => void;
  isLarge?: boolean;
  currentFilter?: string[];
}

export const Filter = ({
  label,
  placeholder,
  disabled,
  options,
  onChangeFunc,
  currentFilter,
  isLarge = false,
}: InputProps) => {
  const translations = withTranslations();
  const router = useRouter();
  const shouldUseDefaultLocale = shouldForceDefaultLocale(router);
  const id = useId();
  return (
    <Box
      position='relative'
      display='inline-block'
      textStyle={'mobile.body.s'}
      w={{ base: isLarge ? '280px' : '100%', lg: 'auto' }}
      sx={{
        ".inputBox_ramboll[aria-invalid='true'] + div": {
          display: 'inline-block',
        },
      }}
      pr={{ base: '0', lg: 'm' }}
      pt={{ base: 'xs', lg: '0' }}
    >
      {label && (
        <FormLabel
          htmlFor={id}
          color={disabled ? 'mountain.60' : 'mountain.base'}
          margin='0'
          textStyle={'mobile.body.xs'}
          fontWeight='325'
          aria-label={translations.aria(
            'openFilterForLabel',
            { label: translations.multiple(label, null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }) },
            { ...(shouldUseDefaultLocale && { ns: 'english' }) },
          )}
          pb='5px'
        >
          {toTitleCase(translations.multiple(label, null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }))}
        </FormLabel>
      )}

      {/* -- Select -- */}
      <Select
        className='inputBox_ramboll'
        id={id}
        isDisabled={disabled}
        placeholder={placeholder}
        _placeholder={{
          color: 'mountain.60',
          transition: 'color 0.2s',
        }}
        background={'gray.98'}
        position='relative'
        w={{ base: isLarge ? '280px' : '100%', lg: isLarge ? '280px' : '200px' }}
        h={isLarge ? '60px' : '48px'}
        display='block'
        borderRadius='0'
        border='2px solid'
        borderColor='mountain.20'
        value={currentFilter?.[0] || ''}
        onChange={(e) => onChangeFunc(e.target.value)}
      >
        {options.map((option: any, index: number) => {
          // For project, product, insight and news pages
          return option.amountOfPagesWithTag > 0 && option.name ? (
            <option key={index} value={option.codename}>
              {option.name} {`(${option.amountOfPagesWithTag})`}
            </option>
          ) : // For careers page
          option.amountOfOffersWithTag > 0 ? (
            <option key={index} value={option.apiCodename}>
              {option.name} {`(${option.amountOfOffersWithTag})`}
            </option>
          ) : typeof option === 'string' ? (
            <option key={index} value={option}>
              {option}
            </option>
          ) : null;
        })}
      </Select>
      {/* -- Select End -- */}
    </Box>
  );
};
