import { Box, Container, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { toTitleCase } from 'helpers/textCase';
import { ContactUsMetadata } from 'models';
import { useRouter } from 'next/router';
import { withTranslations } from 'store/translations';

import { HeadComponent } from '../Head';
import GeneralOffice from './GeneralOffice';
import People from './People';
import { GeneralOfficeType } from './types';

type PeoplePageProps = {
  generalOffice: GeneralOfficeType | null;
  contactUsMetaData?: ContactUsMetadata;
};

const PeoplePage = ({ generalOffice, contactUsMetaData }: PeoplePageProps) => {
  const { push } = useRouter();
  const { common, multiple } = withTranslations();

  const handleTabChange = () => push(`/${multiple('contactUsUrl')}`);

  const tabStyles = {
    textStyle: { base: 'mobile.body.s', lg: 'desktop.body.l' },
    w: { base: '110px', lg: '220px' },
  };

  return (
    <>
      <HeadComponent page={contactUsMetaData}></HeadComponent>
      <Tabs index={1} onChange={handleTabChange}>
        <Box bg='pebble.20' pb={{ base: 'xs', lg: '0' }} pt={{ base: 'm', lg: 's' }}>
          <Container>
            <Flex direction={{ base: 'column', lg: 'row' }} gap={{ base: 's', lg: '0' }}>
              <Heading
                as='h1'
                color='mountain.base'
                flex={{ base: 'unset', lg: '1' }}
                textStyle={{ base: 'mobile.h3', lg: 'desktop.h4' }}
              >
                {common('contactUs')}
              </Heading>
              {generalOffice && <GeneralOffice {...generalOffice} />}
            </Flex>
            <TabList
              borderBottom='1px solid'
              borderBottomColor='mountain.20'
              pt={{ base: 's', lg: 'xs' }}
              w='fit-content'
            >
              <Tab {...tabStyles}>{toTitleCase(common('offices'))}</Tab>
              <Tab {...tabStyles}>{toTitleCase(common('people'))}</Tab>
            </TabList>
          </Container>
        </Box>
        <TabPanels>
          {/*I don't know what this is supposed to be */}
          <TabPanel>Placeholder</TabPanel>
          <TabPanel>
            <People />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default PeoplePage;
