import { IPagination } from '@kontent-ai/delivery-sdk';
import { Contact } from 'models';
import { create } from 'zustand';

export interface PaginatedPeopleStoreProps {
  names?: string[];
  setNames: (value: string[]) => void;
  people?: Omit<Contact['elements'], 'key'>[];
  setPeople: (value: Contact['elements'][]) => void;
  pagination?: IPagination;
  setPagination: (value: IPagination) => void;
  departments?: string[];
  setDepartments: (value: string[]) => void;
  cities?: string[];
  setCities: (value: string[]) => void;
  selectedCity: string;
  setSelectedCity: (value: string) => void;
  countries?: string[];
  setCountries: (value: string[]) => void;
  selectedCountry: string;
  setSelectedCountry: (value: string) => void;
  markets?: string[];
  setMarkets: (value: string[]) => void;
  selectedMarket: string;
  setSelectedMarket: (value: string) => void;
  selectedDepartment: string;
  setSelectedDepartment: (value: string) => void;
}

export const usePaginatedPeople = create<PaginatedPeopleStoreProps>((set) => ({
  names: undefined,
  setNames: (value) => set({ names: value }),
  people: undefined,
  setPeople: (value) => set({ people: value }),
  pagination: undefined,
  setPagination: (value) => set({ pagination: value }),
  departments: undefined,
  setDepartments: (value) => set({ departments: value }),
  cities: undefined,
  setCities: (value) => set({ cities: value }),
  selectedCity: '',
  setSelectedCity: (value) => set({ selectedCity: value }),
  countries: undefined,
  setCountries: (value) => set({ countries: value }),
  selectedCountry: '',
  setSelectedCountry: (value) => set({ selectedCountry: value }),
  markets: undefined,
  setMarkets: (value) => set({ markets: value }),
  selectedMarket: '',
  setSelectedMarket: (value) => set({ selectedMarket: value }),
  selectedDepartment: '',
  setSelectedDepartment: (value: string) => set({ selectedDepartment: value }),
}));
