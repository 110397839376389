import { Box, Container, Flex, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import Toggle from 'components/base/Toggle';
import { toTitleCase } from 'helpers/textCase';
import { OfficeOverviewMetadata } from 'models';
import { useRouter } from 'next/router';
import { withTranslations } from 'store/translations';

import { HeadComponent } from '../Head';
import GeneralOffice from './GeneralOffice';
import OfficesMap from './OfficesMap';
import { GeneralOfficeType, Marker } from './types';

type MapPageProps = {
  generalOffice: GeneralOfficeType | null;
  markers: Marker[];
  officeOverviewMetadata: OfficeOverviewMetadata;
};

const MapPage = ({ generalOffice, markers, officeOverviewMetadata }: MapPageProps) => {
  const { common, multiple, url } = withTranslations();
  const { push } = useRouter();

  const handleTabChange = () => push(`/${multiple('contactUsUrl')}/${url('peopleUrl')}`);

  const handleToggleOnChange = () => push(`/${multiple('contactUsUrl')}`);

  const tabStyles = {
    textStyle: { base: 'mobile.body.s', lg: 'desktop.body.l' },
    w: { base: '110px', lg: '220px' },
  };

  return (
    <>
      <HeadComponent page={officeOverviewMetadata}></HeadComponent>
      <Tabs index={0} onChange={handleTabChange}>
        <Box bg='pebble.20' pb={{ base: 'xs', lg: '0' }} pt='m'>
          <Container>
            <Flex direction={{ base: 'column', lg: 'row' }} gap={{ base: 's', lg: '0' }}>
              <Heading
                as='h1'
                color='mountain.base'
                flex={{ base: 'unset', lg: '1' }}
                textStyle={{ base: 'mobile.h3', lg: 'desktop.h4' }}
              >
                {common('contactUs')}
              </Heading>
              {generalOffice && <GeneralOffice {...generalOffice} />}
            </Flex>
            <TabList
              borderBottom='1px solid'
              borderBottomColor='mountain.20'
              pt={{ base: 's', lg: 'lr' }}
              w='fit-content'
            >
              <Tab {...tabStyles}>{toTitleCase(multiple('office'))}</Tab>
              <Tab {...tabStyles}>{toTitleCase(multiple('people'))}</Tab>
            </TabList>
          </Container>
        </Box>
        <TabPanels>
          <TabPanel>
            <Box pb='l' pt={{ base: 'xs', lg: 's' }}>
              <Container>
                <Flex align='center' justify='end' mb={{ base: 'xs', lg: 's' }}>
                  <Text
                    color='mountain.base'
                    display={{ base: 'none', lg: 'block' }}
                    pr='xs'
                    textStyle='desktop.body.s'
                  >
                    {common('viewAs')}
                  </Text>
                  <Toggle value='map' onChange={handleToggleOnChange} />
                </Flex>
                <OfficesMap markers={markers} />
              </Container>
            </Box>
          </TabPanel>
          {/* I don't know what is this supposed to be */}
          <TabPanel>Placeholder</TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default MapPage;
