import { Box, FormLabel, Input as ChakraInput } from '@chakra-ui/react';
import React, { useId } from 'react';

import { IconComp } from './IconComp';

interface InputProps {
  onWhite: boolean;
  label: string;
  placeholder: string;
  value?: string;
  onChangeFunc?: (value: string) => void;
  disabled?: boolean;
  type?: 'email' | 'search' | 'text' | 'password';
}

export const Input = ({ onWhite, label, placeholder, disabled, type, onChangeFunc, value }: InputProps) => {
  const id = useId();

  return (
    <Box
      position='relative'
      display='inline-block'
      w={{ base: '100%', lg: 'auto' }}
      sx={{
        ".inputBox_ramboll[aria-invalid='true'] + div": {
          display: 'inline-block',
        },
      }}
    >
      <FormLabel
        htmlFor={id}
        color={disabled ? 'mountain.60' : 'mountain.base'}
        margin='0'
        textStyle={'mobile.body.xs'}
        fontWeight='325'
        pb='5px'
      >
        {label}
      </FormLabel>
      <ChakraInput
        className='inputBox_ramboll'
        id={id}
        isDisabled={disabled}
        placeholder={placeholder}
        type={type}
        value={value}
        textStyle={'mobile.body.xs'}
        _placeholder={{
          color: 'mountain.60',
          transition: 'color 0.2s',
        }}
        _hover={{
          background: !disabled && 'gray.red',
          _placeholder: {
            color: !disabled && 'mountain.base',
          },
        }}
        _invalid={{
          border: '2px solid',
          borderColor: 'field',
        }}
        _disabled={{
          borderColor: 'mountain.10',
        }}
        background={onWhite ? 'gray.98' : 'white'}
        position='relative'
        w={{ base: '100%', lg: '300px' }}
        h='48px'
        display='block'
        borderRadius='0'
        border='2px solid var(--chakra-colors-mountain-20)'
        onChange={(e) => onChangeFunc?.(e.target.value)}
      />
      {type === 'search' && value === '' && (
        <IconComp
          iconName='search'
          chakraStyle={{
            height: '24px',
            width: '24px',
            position: 'absolute',
            bottom: '24px',
            transform: 'translateY(50%)',
            right: '10px',
            path: { fill: 'mountain.base' },
          }}
        />
      )}
    </Box>
  );
};
