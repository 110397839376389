import { Box, Container, Flex, Heading, useDisclosure, VStack } from '@chakra-ui/react';
import { getButtonWithLinkStyling } from 'components/base/Button';
import { Link as CustomLink } from 'components/base/Link';
import { HeadComponentContact } from 'components/pages/Head';
import { LinkType } from 'lib/enums/LinkType';
import { Contact, Contact as ContactModel, FormModal } from 'models';
import { useRouter } from 'next/router';
import { withTranslations } from 'store/translations';

import { ContactFormModal } from '../../modules/ContactFormModal';
import Person from './Person';

type ContactUsPersonProps = {
  person: Omit<ContactModel['elements'], 'key'>;
  form: FormModal | null;
  contact: Contact;
};

const ContactUsPerson = ({ person, form, contact }: ContactUsPersonProps) => {
  const { common, multiple, url, aria } = withTranslations();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();
  return (
    <>
      <HeadComponentContact page={contact}></HeadComponentContact>
      <Box bg='pebble.20' py='m'>
        <Container>
          <CustomLink
            shallow
            colorScheme='mountain.base'
            href={`/${multiple('contactUsUrl')}/${url('peopleUrl')}`}
            iconName='chevronLeft'
            type={LinkType.SecondaryButton}
            locale={router.locale}
          >
            {common('contactUs')}
          </CustomLink>
          <Heading
            as='h1'
            color='mountain.base'
            flex={{ base: 'unset', lg: '1' }}
            mt='s'
            textStyle={{ base: 'mobile.h3', lg: 'desktop.h4' }}
          >
            {common('contactUs')}
          </Heading>
        </Container>
      </Box>
      <Container pt='l' pb='xl'>
        <Flex align='center' gap='30%' flexDirection={{ base: 'column', md: 'row' }}>
          <VStack mb={'xs'}>
            <Person hasContact={false} {...person} />
            {form && (
              <Box w='100%' textAlign={{ base: 'center', md: 'left' }}>
                {getButtonWithLinkStyling(
                  `${aria('openContactGetInTouchWith')} ${person.displayName.value}`,
                  common('getInTouch'),
                  onOpen,
                  form.system.name,
                )}
              </Box>
            )}
          </VStack>
          {person.description.value && (
            <Box
              color='black'
              mb='s'
              textStyle='mobile.body.l'
              dangerouslySetInnerHTML={{ __html: person.description.value }}
            />
          )}
          {form && (
            <ContactFormModal
              id={`${person.id}${form.system.id}`}
              model={form}
              isOpen={isOpen}
              contactPersonEmail={person.email.value}
              onClose={onClose}
            />
          )}
        </Flex>
      </Container>
    </>
  );
};

export default ContactUsPerson;
