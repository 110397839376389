import { Box, ChakraProvider, Link, Stack, StackDivider, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { Translate } from 'next-translate';
import lightTheme from 'themes/lightTheme';

type Coordinates = {
  lat: number;
  lng: number;
};

export type GoogleMapInfoWindowProps = {
  address?: string;
  description?: string;
  phoneNumber?: string;
  position: Coordinates;
  title: string;
  url?: string;
  common: Translate;
};

const GoogleMapInfoWindow = ({
  address,
  description,
  phoneNumber,
  position,
  title,
  url,
  common,
}: GoogleMapInfoWindowProps) => {
  return (
    <ChakraProvider theme={lightTheme}>
      <Box maxW='350px' p='3xs'>
        <Text as='h3' color='mountain.base' mb='xs' textStyle='desktop.body.medium.s'>
          {title}
        </Text>
        {description && (
          <Box
            color='mountain.base'
            dangerouslySetInnerHTML={{ __html: description }}
            mb='xs'
            textStyle='desktop.body.s'
          />
        )}
        {address && (
          <Box color='mountain.base' dangerouslySetInnerHTML={{ __html: address }} mb='xs' textStyle='desktop.body.s' />
        )}
        {phoneNumber && (
          <Text color='mountain.base' mb='xs' textStyle='desktop.body.s'>
            {phoneNumber}
          </Text>
        )}
        <Stack color='cyan.a11y' direction='row' divider={<StackDivider borderColor='cyan.a11y' />} spacing='xxs'>
          <Link
            isExternal
            href={`http://maps.google.com/maps?q=&layer=c&cbll=${position.lat},${position.lng}`}
            p='0'
            textStyle='mobile.body.xs'
          >
            {common('streetView')}
          </Link>
          <Link
            isExternal
            p='0'
            textStyle='mobile.body.xs'
            href={`http://www.google.com/maps/place/${position.lat},${position.lng}`}
            // href={`http://www.google.com/maps/dir/${START_COORDINATES}/${DESTINATION_COORDINATES}`}
          >
            {common('findAWay')}
          </Link>
        </Stack>
        {url && (
          <Link as={NextLink} color='cyan.a11y' display='block' href={url} mt='xs' p='0' textStyle='mobile.body.xs'>
            {common('readMore')}
          </Link>
        )}
      </Box>
    </ChakraProvider>
  );
};

export default GoogleMapInfoWindow;
