import { Box, Flex, Text } from '@chakra-ui/react';
import { Filter } from 'components/base/Filter';
import { MultipleChoiceFilter } from 'components/base/MultipleChoiceFilter';
import { PaginationNew } from 'components/base/PaginationNew';
import { PAGINATION_LIST_LIMIT } from 'lib/constants';
import cacheData from 'memory-cache';
import { ContentPage } from 'models';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { withTranslations } from 'store/translations';
import { ItemBlock, OptionsProps } from 'utils/overviewFunctionality';

import EmptyContent from '../../../public/images/empty-content.svg';

export type FilterType = {
  label: string;
  set: (string) => void;
  state: OptionsProps[];
  value: string[];
};
export interface OverviewProps {
  PagesToShow: ContentPage[][];
  amountOfFilters: number;
  overviewTitle: string;
  filterOne?: FilterType;
  filterTwo?: FilterType;
  filterThree?: FilterType;
  manualItems?: ContentPage[];
}

export default function OverviewTemplate({
  PagesToShow,
  overviewTitle,
  filterOne,
  filterTwo,
  filterThree,
  amountOfFilters,
}: OverviewProps) {
  const { aria, common } = withTranslations();
  const [pagination, setPagination] = useState(0);
  const searchTopRef = useRef<HTMLParagraphElement>(null);
  const showingAmountOfPagesRef = useRef<HTMLDivElement>(null);
  const initialValue = 0;
  const amountOfPages = PagesToShow.reduce(
    (accumulator, currentValue) => accumulator + currentValue.length,
    initialValue,
  );
  const router = useRouter();
  useEffect(() => {
    setPagination(0);
  }, [router.locale]);
  useEffect(() => {
    // if(overviewTitle == 'insights')
    // {
    if (cacheData.get('insight_theme') != null) {
      filterTwo?.set([cacheData.get('insight_theme')]);
      setPagination(0);
    } else {
      filterTwo?.set([]);
      setPagination(0);
    }
    //}
  }, [cacheData.get('insight_theme')]);

  // -- Main return --
  return (
    <Box w={'100%'} maxW='1440px' margin='auto'>
      <Text
        // ref={searchTopRef}
        as='h1'
        //   display='block'
        textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }}
        pt={{ base: '0', lg: 'l' }}
        pb={{ base: 'xs', lg: 'xs' }}
        pl={{ base: 'xs', lg: 'm' }}
        w='100%'
      >
        {common('searchOurOverview', { overviewTitle })}
      </Text>

      {/* -- Filters -- */}
      <Flex
        p={{ base: 'xs', lg: 'l' }}
        pt={{ base: '0', lg: 's' }}
        pb='s'
        flexDirection={{ base: 'column', lg: 'row' }}
        flexWrap='wrap'
        w='100%'
        position='relative'
      >
        {/* -- Location Filter -- */}
        {filterOne && (
          <MultipleChoiceFilter
            label={filterOne.label}
            placeholder={common('all')}
            disabled={false}
            options={filterOne.state}
            currentFilters={filterOne.value}
            clearAll={() => filterOne.set([])}
            onWhite={false}
            onCheckFunc={(value: string) => {
              filterOne.set([...filterOne.value, value]);
              setPagination(0);
            }}
            onUnCheckFunc={(value: string) => {
              filterOne.set(
                filterOne.value.filter((item) => {
                  return item !== value;
                }),
              );
              setPagination(0);
            }}
          />
        )}

        {/* -- Location Filter End -- */}

        {/* -- Tags Filter -- */}
        {filterTwo && (
          <MultipleChoiceFilter
            label={filterTwo.label}
            placeholder={common('all')}
            disabled={false}
            options={filterTwo.state}
            currentFilters={filterTwo.value}
            clearAll={() => filterTwo.set([])}
            onWhite={false}
            onCheckFunc={(value: string) => {
              filterTwo.set([...filterTwo.value, value]);
              setPagination(0);
            }}
            onUnCheckFunc={(value: string) => {
              filterTwo.set(
                filterTwo.value.filter((item) => {
                  return item !== value;
                }),
              );
              setPagination(0);
            }}
          />
        )}

        {/* -- Tags Filter End -- */}

        {/* -- Tags Service -- */}
        {filterThree && (
          <Filter
            label={filterThree.label}
            placeholder={common('all')}
            disabled={false}
            options={filterThree.state}
            currentFilter={filterThree.value}
            onWhite={false}
            onChangeFunc={(value: string) => {
              // '' means pressing "All"
              if (value !== '') {
                filterThree.set([value]);
                setPagination(0);
              } else {
                filterThree.set([]);
                setPagination(0);
              }
            }}
          />
        )}

        {/* -- Tags Service End -- */}

        <Text
          as='button'
          color='cyan.web'
          display={amountOfFilters !== 0 ? 'unset' : 'none'}
          textStyle={'desktop.body.medium.s'}
          position={{ base: 'relative', lg: 'absolute' }}
          right={{ base: 'unset', lg: 'l' }}
          top={{ base: 'unset', lg: '50%' }}
          transform={{ base: 'unset', lg: 'translateY(-50%)' }}
          alignSelf={{ base: 'flex-end', lg: 'unset' }}
          pr={{ base: '0', lg: 'm' }}
          pt={{ base: 'xs', lg: '0' }}
          onClick={() => {
            filterOne?.set([]);
            filterTwo?.set([]);
            filterThree?.set([]);
            setPagination(0);
            cacheData.put('insight_theme', null);
          }}
        >
          {common('clearFilters')} {`(${amountOfFilters})`}
        </Text>
      </Flex>
      {/* -- Filters end -- */}

      {amountOfPages !== 0 ? (
        <Box
          ref={showingAmountOfPagesRef}
          textStyle={'desktop.body.book.s'}
          pl={{ base: 'xs', lg: 'l' }}
          pb={{ base: 's', lg: 'l' }}
          aria-label={aria('showingPages', {
            itemsShown: `${PAGINATION_LIST_LIMIT * pagination + 1} to ${
              PagesToShow[pagination].length <= PAGINATION_LIST_LIMIT
                ? PagesToShow[pagination].length + pagination * PAGINATION_LIST_LIMIT
                : PAGINATION_LIST_LIMIT
            }`,
            total: amountOfPages,
          })}
          dangerouslySetInnerHTML={{
            __html: common('showingResults', {
              itemsShown: `${PAGINATION_LIST_LIMIT * pagination + 1} to ${
                PagesToShow[pagination].length <= PAGINATION_LIST_LIMIT
                  ? PagesToShow[pagination].length + pagination * PAGINATION_LIST_LIMIT
                  : PAGINATION_LIST_LIMIT
              }`,
              total: amountOfPages,
            }),
          }}
        />
      ) : (
        <Box
          textStyle={'desktop.body.medium.s'}
          pl={{ base: 'xs', lg: 'l' }}
          pb={{ base: 's', lg: 'l' }}
          dangerouslySetInnerHTML={{ __html: common('showingNoResults') }}
        />
      )}

      <Flex
        w={'100%'}
        flexDirection='row'
        flexWrap={'wrap'}
        justifyContent={overviewTitle !== 'news' ? 'space-between' : 'unset'}
        pl={{ base: 'xs', lg: 's' }}
        pr={{ base: 'xs', lg: 's' }}
      >
        {PagesToShow[0].length > 0 ? (
          PagesToShow[pagination].map((page) => {
            return <ItemBlock key={page.system.id} page={page} />;
          })
        ) : (
          <Flex pb='l'>
            <Box w={{ base: '100%', lg: '40%' }} display='block'>
              <Text textStyle={'desktop.h4'} pb='s'>
                {common('filterDidntMatch')}
              </Text>
              <Text textStyle={'desktop.body.s'}>{common('filterDidntMatchClearFilters')}</Text>
            </Box>
            <Box w='60%' display={{ base: 'none', lg: 'unset' }}>
              <EmptyContent />
            </Box>
          </Flex>
        )}
      </Flex>
      <Box
        display={PagesToShow.length > 1 ? 'inline-block' : 'none'}
        position='relative'
        left='50%'
        transform='translate(-50%)'
      >
        <PaginationNew
          total={PagesToShow.flatMap((arr) => arr).length}
          perPage={PAGINATION_LIST_LIMIT}
          current={pagination + 1}
          onNavigate={(value: number) => {
            setPagination(value - 1);
            searchTopRef.current?.scrollIntoView({ behavior: 'smooth' });
            showingAmountOfPagesRef.current?.focus();
          }}
        />
      </Box>
    </Box>
  );
}
// -- Main return End --
