import { Contact, Contact as ContactModel, FormModal } from 'models';

import ContactUsPerson from './ContactUsPerson';

export type SinglePersonPageProps = {
  person: Omit<ContactModel['elements'], 'key'> | null;
  form: FormModal | null;
  contact: Contact;
};

const SinglePersonPage = ({ person, form, contact }: SinglePersonPageProps) => {
  if (!person) {
    return <></>;
  }
  return <ContactUsPerson person={person} form={form} contact={contact} />;
};

export default SinglePersonPage;
