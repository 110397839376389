import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { ProdClient } from 'client/client';
import Location from 'components/pages/ContactUs/Location';
import _compact from 'lodash/compact';
import _uniq from 'lodash/uniq';
import { ContentPage as ContentPageModel, Office as OfficeModel } from 'models';
import { GetStaticPaths } from 'next';
import { getContactUsVariants } from 'utils/overviewVariants';

export const getStaticPaths: GetStaticPaths = () => {
  return {
    fallback: 'blocking',
    paths: [],
  };
};

export const getStaticCountryProps = async ({ urlParam, locale }: { locale: string; urlParam: string }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;

  const [
    {
      data: { items: contentPageModel },
    },
    {
      data: { items: officesModel },
    },
  ] = await Promise.all([
    ProdClient.items<ContentPageModel>()
      .type('content_page')
      .containsFilter('elements.category', ['office'])
      .equalsFilter('elements.url', urlParam)
      .languageParameter(languageCodename)
      .limitParameter(1)
      .depthParameter(3)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise(),
    ProdClient.items<OfficeModel>()
      .type('office')
      .anyFilter('elements.country', [urlParam])
      .orderByAscending('elements.isHeadOffice')
      .languageParameter(languageCodename)
      .limitParameter(3)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise(),
  ]);
  const variants = await getContactUsVariants();

  return {
    contentPageModel: contentPageModel[0] ?? null,
    variants,
    offices: officesModel.map(({ elements, system }) => ({
      address: createRichTextHtmlResolver(nodeParser).resolveRichText({
        element: elements.address,
      }).html,
      city: elements.city.value,
      country: elements.country.value[0].name,
      cvr: elements.taxNumber.value,
      description: createRichTextHtmlResolver(nodeParser).resolveRichText({
        element: elements.info,
      }).html,
      email: elements.email.value,
      id: system.id,
      isMain: !!elements.isHeadOffice.value[0]?.name,
      officeName: elements.officeName.value || null,
      phone: elements.phoneNumber.value,
    })),
  };
};

const LocationPage = (props: Awaited<ReturnType<typeof getStaticCountryProps>>) => <Location {...props} />;

export default LocationPage;
