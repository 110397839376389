import { META_TITLE_POSTFIX } from 'lib/constants';
import Head from 'next/head';
import { getHost } from 'utils/getHost';

interface IdPagesMetaDataProps {
  category: string;
  title: string;
  description: string;
  url: string;
  image?: string;
  ogImage?: string;
  twitterImage?: string;
}

const IdPagesMetaData = ({ category, title, description, url, ogImage, twitterImage }: IdPagesMetaDataProps) => {
  // console.log('url of vacancy page ',url)
  return (
    <Head>
      <meta name='category' content={category} />
      {/* TODO: After localization is solved, add hreflang link attributes */}
      {/* <link rel='canonical' href={''} />
    <meta property='og:locale' content={metaLocale(locale)} /> */}

      <title>{title.replace(/<\/?[^>]+(>|$)/g, '') + META_TITLE_POSTFIX}</title>
      <meta name='title' content={title.replace(/<\/?[^>]+(>|$)/g, '') + META_TITLE_POSTFIX} />
      <meta name='description' content={description.replace(/<\/?[^>]+(>|$)/g, '')} />
      <link rel='canonical' href={url} />

      <meta property='og:type' content='article' />
      <meta property='og:url' content={url} />
      <meta property='og:title' content={title.replace(/<\/?[^>]+(>|$)/g, '') + META_TITLE_POSTFIX} />
      <meta property='og:description' content={description.replace(/<\/?[^>]+(>|$)/g, '')} />
      <meta property='og:image' content={ogImage?.replace(/<\/?[^>]+(>|$)/g, '')} />

      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:title' content={title.replace(/<\/?[^>]+(>|$)/g, '') + META_TITLE_POSTFIX} />
      <meta property='twitter:description' content={description.replace(/<\/?[^>]+(>|$)/g, '')} />
      <meta property='twitter:image' content={twitterImage?.replace(/<\/?[^>]+(>|$)/g, '')} />
    </Head>
  );
};

export default IdPagesMetaData;
