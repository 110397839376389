import CareersOverview, { getStaticCareersProps } from 'components/pages/overview/careers';
import VacancyPage, { getCareerIdProps } from 'components/pages/overview/careers/[id]';
import TalentCommunitiesPage, {
  getStaticTalentCommunityProps,
} from 'components/pages/overview/careers/talent-communities';
import ContactUsOfficesPage, { getStaticContactUsProps } from 'components/pages/overview/contact-us';
import LocationPage, { getStaticCountryProps } from 'components/pages/overview/contact-us/[codename]';
import ContactUsMapPage, { getStaticMapProps } from 'components/pages/overview/contact-us/map';
import ContactUsPeoplePage, { getStaticPeopleProps } from 'components/pages/overview/contact-us/people';
import ContactUsSinglePersonPage, { getStaticContactProps } from 'components/pages/overview/contact-us/people/[id]';
import InsightsOverview, { getStaticInsightsProps } from 'components/pages/overview/insights';
import NewsOverview, { getStaticNewsProps } from 'components/pages/overview/news';
import ProductsOverview, { getStaticProductProps } from 'components/pages/overview/products';
import ProjectOverview, { getStaticProjectsProps } from 'components/pages/overview/projects';
import { Category } from 'models';

import { getOverviewVariants } from './overviewVariants';

export const getSubpageComponent = (subpageType: string, codename?: string, type?: string) => {
  switch (subpageType) {
    case 'insight':
      return InsightsOverview;
    case 'project':
      return ProjectOverview;
    case 'news':
      return NewsOverview;
    case 'product':
      return ProductsOverview;
    case 'career':
      if (codename?.includes('talentCommunitiesUrl')) return TalentCommunitiesPage;
      if (codename) return VacancyPage;
      return CareersOverview;
    case 'contactUsUrl':
      if (codename?.includes('mapUrl')) return ContactUsMapPage;
      if (codename?.includes('peopleUrl')) return ContactUsPeoplePage;
      if (type?.includes('office')) return LocationPage;
      if (type?.includes('contact')) return ContactUsSinglePersonPage;
      return ContactUsOfficesPage;
    default:
      return null;
  }
};

export const getSubpageProps = async (
  subpageType: string,
  locale: string,
  urlParam?: string,
  type?: string,
  params?: { page: string; city: string; department: string },
) => {
  switch (subpageType) {
    case 'insight':
      return await getStaticInsightsProps({ locale, urlParam });
    case 'project':
      return await getStaticProjectsProps({ locale, urlParam });
    case 'news':
      return urlParam ? null : await getStaticNewsProps({ locale });
    case 'product':
      return await getStaticProductProps({ locale, urlParam });
    case 'career':
      if (urlParam?.includes('talentCommunitiesUrl')) return await getStaticTalentCommunityProps({ locale });
      if (urlParam) return await getCareerIdProps({ locale, urlParam });
      return await getStaticCareersProps({ locale });
    case 'contactUsUrl':
      if (urlParam?.includes('mapUrl')) return await getStaticMapProps({ locale });
      if (urlParam?.includes('peopleUrl')) return await getStaticPeopleProps({ locale, params });
      if (urlParam && type?.includes('office')) return await getStaticCountryProps({ locale, urlParam });
      if (urlParam && type?.includes('contact')) return await getStaticContactProps({ locale, urlParam });
      return await getStaticContactUsProps({ locale });
    default:
      return null;
  }
};

export const fetchOverviewVariants = async (overviewType: Category) => {
  return await getOverviewVariants(overviewType);
};
