import { isArray } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import slugify from 'slugify';

type LocaleData = {
  [key: string]: string;
};

const loadLocaleData = (locale: string, fileName: string): Promise<LocaleData> =>
  import(`/locales/${locale}/${fileName}.json`).then((mod) => {
    return mod.default;
  });

export const getLocaleKeyFromValue = async (locale: string, fileName: string, value: string | string[]) => {
  const data = await loadLocaleData(locale, fileName);
  const foundKey = !isArray(value)
    ? Object.keys(data).filter((key) => slugify(data[key]).toLowerCase() === value)
    : value.map((item) => Object.keys(data).filter((key) => slugify(data[key]).toLowerCase() === item)).flat();
  return foundKey;
};

export const fetchInitialUrlFilter = async (
  localeFilename: string,
  value: string | string[],
  setterFn: Dispatch<SetStateAction<string[]>>,
  locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
) => {
  const translatedUrlFilter = await getLocaleKeyFromValue(locale, localeFilename, value);
  setterFn(translatedUrlFilter);
};
