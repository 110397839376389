import { ITaxonomyTerms } from '@kontent-ai/delivery-sdk';
import { ProdClient } from 'client/client';
import { FeaturedContent } from 'components/modules/FeaturedContent';
import { HeadComponent } from 'components/pages/Head';
import OverviewTemplate from 'components/pages/Overview';
import { fetchInitialUrlFilter } from 'helpers/getLocaleKey';
import cacheData from 'memory-cache';
import { ContentPage, FeaturedSpots, OverviewPage } from 'models';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import slugify from 'slugify';
import { withTranslations } from 'store/translations';
import { alphabeticalSort } from 'utils/alphabeticalSort';
import { onlyDefined } from 'utils/definedInArray';
import { fetchOverviewVariants } from 'utils/getOverviewPage';
import { OptionsProps } from 'utils/overviewFunctionality';

interface ProductsOverviewProps {
  ContentPages: ContentPage[];
  FeaturedSpot: FeaturedSpots;
  Sector: ITaxonomyTerms[];
  Location: ITaxonomyTerms[];
  ServiceLines: ITaxonomyTerms[];
  urlSector?: string;
  OverviewPage: OverviewPage;
}

export default function ProductsOverview({
  ContentPages,
  FeaturedSpot,
  Sector,
  /* Location,
  ServiceLines, */
  urlSector,
  OverviewPage,
}: ProductsOverviewProps) {
  const { multiple, sector: sectorTranslate, common } = withTranslations();
  const translations = withTranslations();
  // ------------------------ FUNCTIONS ------------------------
  // -- Divide pages to show into arrays of 10 pages --
  const dividePagesToShow = () => {
    let dividePagesByTen = 0;
    // Use this instead of index due to some pages not showing w/ filters
    let currentIndex = 0;
    const pagesToShowTemp: [ContentPage[]] = [[]];
    ContentPages.forEach((page) => {
      if (currentIndex % 10 === 0 && currentIndex !== 0 && pagesToShowTemp[dividePagesByTen]?.length >= 10) {
        dividePagesByTen++;
      }
      if (page.elements.tagsSector.value?.length > 0) {
        page.elements.tagsSector.value.forEach((sector) => {
          const sectorCodename = sector?.codename;
          /* const locationCodename = page.elements.tagsLocation.value[0]?.codename;
        const serviceCodename = page.elements.tagsService.value[0]?.codename; */
          if (
            filterSector.includes(sectorCodename) ||
            !filterSector[0] /*  &&
          (filterLocation.includes(locationCodename) || !filterLocation[0]) &&
          (filterService.includes(serviceCodename) || !filterService[0]) */
          ) {
            if (!Array.isArray(pagesToShowTemp[dividePagesByTen])) {
              pagesToShowTemp[dividePagesByTen] = [];
            }
            const containsValue = pagesToShowTemp[dividePagesByTen].some(
              (obj) => obj.system.codename === page.system.codename,
            );
            !containsValue &&
              //  pagesToShowTemp[dividePagesByTen]?.length < 10 &&
              pagesToShowTemp[dividePagesByTen].push(page);
            //console.log(" currentIndex before ",currentIndex)
            pagesToShowTemp[dividePagesByTen].length >= 10 && dividePagesByTen++;

            //  currentIndex++;
            //  console.log(" currentIndex after ",currentIndex)
          }
        });
      } else {
        const sectorCodename = page.elements.tagsSector.value[0]?.codename;
        /* const locationCodename = page.elements.tagsLocation.value[0]?.codename;
      const serviceCodename = page.elements.tagsService.value[0]?.codename; */
        if (
          filterSector.includes(sectorCodename) ||
          !filterSector[0] /*  &&
        (filterLocation.includes(locationCodename) || !filterLocation[0]) &&
        (filterService.includes(serviceCodename) || !filterService[0]) */
        ) {
          if (!Array.isArray(pagesToShowTemp[dividePagesByTen])) {
            pagesToShowTemp[dividePagesByTen] = [];
          }
          //   pagesToShowTemp[dividePagesByTen].push(page);
          pagesToShowTemp[dividePagesByTen]?.length < 10 && pagesToShowTemp[dividePagesByTen].push(page);

          //  currentIndex++;
        }
      }
      currentIndex++;
    });
    return onlyDefined(pagesToShowTemp);
  };

  // -- Functions that check amount of pages with filters --
  /* const updateFilterLocation = () => {
    //setPagination(0);
    return Location.map((location) => {
      let amountOfPagesWithTag = 0;
      ContentPages.forEach((page) => {
        const locationCodename = page.elements.tagsLocation.value[0]?.codename;
        const sectorCodename = page.elements.tagsSector.value[0]?.codename;
        const serviceCodename = page.elements.tagsService.value[0]?.codename;
        if (
          location.codename === locationCodename &&
          (filterSector.includes(sectorCodename) || !filterSector[0]) &&
          (filterService.includes(serviceCodename) || !filterService[0])
        ) {
          amountOfPagesWithTag++;
        }
      });
      return Object.assign({ amountOfPagesWithTag: amountOfPagesWithTag, ...location });
    });
  }; */

  const updateFilterSector = () => {
    //setPagination(0);
    return Sector.map((sector) => {
      let amountOfPagesWithTag = 0;
      ContentPages.forEach((page) => {
        page.elements.tagsSector.value.forEach((tag) => {
          const sectorCodename = tag.codename;
          /* const locationCodename = page.elements.tagsLocation.value[0]?.codename;
          const serviceCodename = page.elements.tagsService.value[0]?.codename; */
          if (
            sector.codename === sectorCodename /*  &&
            (filterLocation.includes(locationCodename) || !filterLocation[0]) &&
            (filterService.includes(serviceCodename) || !filterService[0]) */
          ) {
            amountOfPagesWithTag++;
          }
        });
      });
      return Object.assign({
        amountOfPagesWithTag: amountOfPagesWithTag,
        ...sector,
        name: sectorTranslate(sector.codename),
      });
    }).sort((a, b) => alphabeticalSort(a.name, b.name));
  };

  /* const updateFilterService = () => {
    //setPagination(0);
    return ServiceLines.map((serviceLine) => {
      let amountOfPagesWithTag = 0;
      ContentPages.forEach((page) => {
        const sectorCodename = page.elements.tagsSector.value[0]?.codename;
        const locationCodename = page.elements.tagsLocation.value[0]?.codename;
        const serviceCodename = page.elements.tagsService.value[0]?.codename;
        if (
          serviceLine.codename === serviceCodename &&
          (filterLocation.includes(locationCodename) || !filterLocation[0]) &&
          (filterSector.includes(sectorCodename) || !filterSector[0])
        ) {
          amountOfPagesWithTag++;
        }
      });
      return Object.assign({ amountOfPagesWithTag: amountOfPagesWithTag, ...serviceLine });
    });
  }; */
  // -- Functions that check amount of pages with filters End --

  // -- URL based functions --
  const updateFiltersInUrl = () => {
    const removeQueries = router.asPath.includes('?') && router.asPath.split('?').reverse().pop();
    const urlArray =
      removeQueries !== false && removeQueries !== undefined ? removeQueries.split('/') : router.asPath.split('/');
    urlArray.length >= 3 && urlArray.pop();
    // urlArray.push(...filterSector.map((sector) => slugify(sectorTranslate(sector), { lower: true })));
    const urlString =
      /* // Case 1: There's both a location & a service filter on
      filterLocation[0] && filterService[0]
        ? urlArray.join('/') + `?location=${filterLocation.join(',')}` + `&service=${filterService}`
        : // Case 2: There's only a locations filter
        filterLocation[0] && !filterService[0]
        ? urlArray.join('/') + `?location=${filterLocation.join(',')}`
        : // Case 3: There's only a service filter
        !filterLocation[0] && filterService[0]
        ? urlArray.join('/') + `?service=${filterService}`
        : // Case 4: None of the above
            urlArray.join('/');*/
      filterSector[0]
        ? urlArray.join('/') +
          `?${slugify(common('sector'), { lower: true })}=${filterSector
            .map((sector) => slugify(sectorTranslate(sector), { lower: true }))
            .join(',')}`
        : urlArray.join('/');
    router.replace(urlString, undefined, { shallow: true, scroll: false });
  };

  /* const getServiceFilterFromUrl = () => {
    return ServiceLines.filter((e) => {
      return e.codename === router.asPath.split('service=').slice(-1)[0];
    }).length;
  }; */

  /* const getLocationFilterFromUrl = () => {
    const locationUrl = router.asPath.split('location=').slice(-1)[0].split(',');
    const tempLocations: string[] = [];
    locationUrl.forEach((location) => {
      if (Location.some((e) => e.codename === location)) {
        tempLocations.push(location);
      }
    });
    return tempLocations;
  }; */
  // -- URL based functions End --
  // ------------------------ FUNCTIONS END ------------------------

  // ------------------------ VARIABLES ------------------------
  //   const [filterLocation, setFilterLocation] = useState<string[]>([]);
  const [filterSector, setFilterSector] = useState<string[]>(urlSector ? [urlSector] : []);
  //   const [filterService, setFilterService] = useState<string[]>([]);
  const [amountOfFilters, setAmountOfFilters] = useState(0);
  const [pagesToShow, setPagesToShow] = useState<ContentPage[][]>(dividePagesToShow());
  //   const [locationState, setLocationState] = useState<OptionsProps[]>([]);
  const [sectorState, setSectorState] = useState<OptionsProps[]>([]);
  //   const [serviceState, setServiceState] = useState<OptionsProps[]>([]);

  const router = useRouter();

  // ------------------------ VARIABLES END ------------------------

  // ------------------------ USE EFFECTS ------------------------
  useEffect(() => {
    const newSectors = updateFilterSector();
    /* const newLocations = updateFilterLocation();
    const newServiceLines = updateFilterService(); */
    setSectorState(newSectors);
    /* setLocationState(newLocations);
    setServiceState(newServiceLines); */

    setPagesToShow(dividePagesToShow());

    // If there's a tag in the URL set it to filterSector
    urlSector && fetchInitialUrlFilter('sector', urlSector, setFilterSector, router.locale);

    // -- Getting Filters from URL --
    /* const serviceFromUrl = getServiceFilterFromUrl() > 0 && router.asPath.split('service=').slice(-1);
    serviceFromUrl && setFilterService(serviceFromUrl);
    setFilterLocation(getLocationFilterFromUrl()); */
  }, []);

  useEffect(() => {
    const newSectors = updateFilterSector();
    setSectorState(newSectors);
    setPagesToShow(dividePagesToShow());
    // If there's a tag in the URL set it to filterSector
    urlSector && fetchInitialUrlFilter('sector', urlSector, setFilterSector, router.locale);
    // -- Getting Filters from URL --
  }, [router.locale, router.isReady, translations.isReady]);
  // -- Runs when filters change
  useEffect(() => {
    setAmountOfFilters(filterSector.length);

    updateFiltersInUrl();

    const newSectors = updateFilterSector();
    /* const newLocations = updateFilterLocation();
    const newServiceLines = updateFilterService(); */
    setSectorState(newSectors);
    /* setLocationState(newLocations);
    setServiceState(newServiceLines); */

    setPagesToShow(dividePagesToShow());
  }, [filterSector]);
  // ------------------------ USE EFFECTS END ------------------------

  return (
    <>
      <HeadComponent page={OverviewPage[0]} />
      {!!FeaturedSpot && <FeaturedContent model={FeaturedSpot} />}
      <OverviewTemplate
        PagesToShow={pagesToShow}
        overviewTitle={multiple('product')}
        amountOfFilters={amountOfFilters}
        /*         filterOne={{ value: filterLocation, set: setFilterLocation, state: locationState, label: 'Locations' }}
         */ filterTwo={{
          value: filterSector,
          set: setFilterSector,
          state: sectorState,
          label: 'sector',
        }}
        /*         filterThree={{ value: filterService, set: setFilterService, state: serviceState, label: 'Service Line' }}
         */
      />
    </>
  );
}

export const getStaticProductProps = async ({ locale, urlParam }: { locale: string; urlParam?: string }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const ContentPages = await ProdClient.items<ContentPage>()
    .type('content_page')
    .containsFilter('elements.category', ['product'])
    .languageParameter(languageCodename)
    .equalsFilter('system.language', languageCodename)

    .orderByDescending('elements.summary_date')
    .elementsParameter([
      'featured',
      'category',
      'url',
      'summary__title',
      'summary__description',
      'summary__image_frontify',
      'part_of_campaign_page',
      'summary__date',
      'tags__content_type',
      'tags__sector',
    ])
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items
        .sort((a, b) =>
          Date.parse(a.elements.summaryDate.value ?? a.system.lastModified) <
          Date.parse(b.elements.summaryDate.value ?? b.system.lastModified)
            ? 1
            : -1,
        )
        .sort((a, b) =>
          (a.elements.featured.value[0]?.codename ? a.elements.featured.value[0].codename : 'no') >
          (b.elements.featured.value[0]?.codename ? b.elements.featured.value[0]?.codename : 'no')
            ? -1
            : 1,
        ) as ContentPage[];
    });

  const OverviewPage = await ProdClient.items<OverviewPage>()
    .type('overview_page')
    .languageParameter(languageCodename)
    .containsFilter('elements.category', ['product'])

    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items;
    });

  const FeaturedSpot =
    !!OverviewPage.length &&
    !!OverviewPage[0].elements.hero.value[0] &&
    (await ProdClient.item<FeaturedSpots>(OverviewPage[0].elements.hero.value[0])
      .languageParameter(languageCodename)
      .depthParameter(2)
      .toPromise()
      .then((response) => {
        return response.data.item as FeaturedSpots;
      }));

  /* const Location = await ProdClient.taxonomy('location')
.toPromise()
    .then((response) => {
      return response.data.taxonomy.terms;
    }); */

  const Sector =
    cacheData.get('taxonomy_sector') == null
      ? await ProdClient.taxonomy('sector')
          .toPromise()
          .then((response) => {
            cacheData.put('taxonomy_sector', response.data.taxonomy.terms, 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('taxonomy_sector'); //to clear the existing cache after 24hr
            });
            return response.data.taxonomy.terms;
          })
      : cacheData.get('taxonomy_sector');

  /* const ServiceLines = await ProdClient.taxonomy('service')
.toPromise()
    .then((response) => {
      return response.data.taxonomy.terms;
    }); */

  const variants = await fetchOverviewVariants('product');
  return {
    ContentPages: ContentPages,
    FeaturedSpot: FeaturedSpot,
    Sector: Sector,
    urlSector: urlParam ?? null,
    OverviewPage: OverviewPage,
    variants,
    /*       Location: Location,
      ServiceLines: ServiceLines, */
  };
};
