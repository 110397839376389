import { Box, Container, Flex, StackItem, Text, VStack } from '@chakra-ui/react';
import { Link as CustomLink } from 'components/base/Link';
import { ColorBlockHero } from 'components/modules/ColorBlockHero';
import { LinkType } from 'lib/enums/LinkType';
import { ColorBlockHero as ColorBlockHeroModel, ContentPage as ContentPageModel, OfficePageContent } from 'models';
import ErrorPage from 'pages/error';
import { createElement } from 'react';
import { withTranslations } from 'store/translations';
import { mapper } from 'utils/componentMapper';

import { HeadComponent } from '../Head';
import OfficeInfo, { OfficeInfoProps } from './OfficeInfo';
// import {useRouter} from 'next/router';

export type LocationProps = {
  contentPageModel?: ContentPageModel;
  offices: OfficeInfoProps[];
};

const Location = ({ contentPageModel, offices }: LocationProps) => {
  const { common, multiple } = withTranslations();
  // const router = useRouter()

  const colorBlockHeroModel = contentPageModel?.elements.hero.linkedItems[0] as ColorBlockHeroModel;
  const pageContent = (contentPageModel?.elements.content.linkedItems[0] as OfficePageContent)?.elements.pageContent
    .linkedItems;
  const contentModulesMap = pageContent?.map((el) => {
    return {
      type: el.system.type,
      category: contentPageModel?.elements.category?.value[0]?.codename,
    };
  });
  const shouldShowAutomaticOffices =
    contentModulesMap && !contentModulesMap.find((el) => el.type === 'editorial_spots' && el.category === 'office');

  if (!contentPageModel) return <ErrorPage />;

  return (
    <>
      <HeadComponent page={contentPageModel} />
      <ColorBlockHero
        backLink={{
          href: `/${multiple('contactUsUrl')}`,
          label: common('contactUs'),
        }}
        model={colorBlockHeroModel}
      />
      {shouldShowAutomaticOffices && (
        <Container py={{ base: 'm', md: 'l' }}>
          <Box mb={{ base: 's', md: 'l' }}>
            <Flex>
              <Text as='h2' color='mountain.base' flex={1} textStyle='desktop.h5'>
                {common('offices')}
              </Text>
              <Box display={{ base: 'none', sm: 'block' }}>
                <CustomLink
                  href={`/${multiple('contactUsUrl')}`}
                  iconName='chevronRightForLink'
                  type={LinkType.SecondaryButton}
                  // locale={router.locale}
                >
                  {common('viewAll')}
                </CustomLink>
              </Box>
            </Flex>
          </Box>
          <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 'm', md: 'l' }}>
            {offices.map(({ id, ...office }) => (
              <OfficeInfo key={id} flex={1} {...office} />
            ))}
          </Flex>
        </Container>
      )}
      {!!pageContent?.length && (
        <VStack shouldWrapChildren spacing={0}>
          {pageContent.map((element: any, index: number) => (
            <StackItem key={index} display='block'>
              {createElement(mapper(element.system.type) as any, { model: element })}
            </StackItem>
          ))}
        </VStack>
      )}
    </>
  );
};

export default Location;
