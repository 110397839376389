import { META_TITLE_POSTFIX } from 'lib/constants';
import Head from 'next/head';

interface TalentMetaDataProps {
  title: string;
  description: string;
  image?: string;
  ogImage?: string;
  twitterImage?: string;
}

const TalentMetaData = ({ title, description, image }: TalentMetaDataProps) => {
  return (
    <Head>
      {/* TODO: After localization is solved, add hreflang link attributes */}
      {/* <link rel='canonical' href={''} />
    <meta property='og:locale' content={metaLocale(locale)} /> */}
      <title>{title?.replace(/<\/?[^>]+(>|$)/g, '') + META_TITLE_POSTFIX}</title>
      <meta name='title' content={title?.replace(/<\/?[^>]+(>|$)/g, '') + META_TITLE_POSTFIX} />
      <meta name='description' content={description?.replace(/<\/?[^>]+(>|$)/g, '')} />
      <meta property='og:title' content={title.replace(/<\/?[^>]+(>|$)/g, '') + META_TITLE_POSTFIX} />
      <meta property='og:description' content={description.replace(/<\/?[^>]+(>|$)/g, '')} />
      <meta property='og:image' content={image?.replace(/<\/?[^>]+(>|$)/g, '')} />
      <meta property='twitter:title' content={title.replace(/<\/?[^>]+(>|$)/g, '') + META_TITLE_POSTFIX} />
      <meta property='twitter:description' content={description.replace(/<\/?[^>]+(>|$)/g, '')} />
      <meta property='twitter:image' content={image?.replace(/<\/?[^>]+(>|$)/g, '')} />
    </Head>
  );
};

export default TalentMetaData;
