import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { GOOGLE_MAP_POSITION, GOOGLE_MAP_ZOOM } from 'lib/constants';
import { cloneElement, isValidElement, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslations } from 'store/translations';

type MarkerData = {
  id: string;
  icon: string;
  infoWindow: string;
  position: {
    lat: number;
    lng: number;
  };
};

type MarkerProps = MarkerData & {
  map?: google.maps.Map;
};

const Marker = ({ icon, infoWindow, map, position }: MarkerProps) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  const infoWindowElement = useMemo(
    () =>
      new window.google.maps.InfoWindow({
        content: infoWindow,
      }),
    [infoWindow],
  );

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        icon,
        map,
        position,
      });
      marker.addListener('click', () => infoWindowElement.open(map, marker));
    }
  }, [icon, infoWindowElement, map, marker, position]);

  return null;
};

type Coordinates = {
  lat: number;
  lng: number;
};

type MapProps = {
  center?: Coordinates;
  children?: React.ReactNode[];
};

const Map = ({ center, children }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new google.maps.Map(ref.current, {
          center: new google.maps.LatLng(GOOGLE_MAP_POSITION.lat, GOOGLE_MAP_POSITION.lng),
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: true,
          zoom: GOOGLE_MAP_ZOOM,
        }),
      );
    }
  }, [map, ref]);

  useEffect(() => {
    if (map && center) {
      map.panTo(center);
    }
  }, [center, map]);

  return (
    <>
      <div ref={ref} />
      {children?.map((child) => {
        if (isValidElement(child)) {
          // set the map prop on the child component
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return cloneElement(child, { map });
        }
      })}
    </>
  );
};

type GoogleMapProps = {
  center?: Coordinates;
  markers: MarkerData[];
};

const GoogleMap = ({ center, markers }: GoogleMapProps) => {
  const { common } = withTranslations();
  const render = (status: Status) => {
    if (status === Status.FAILURE) {
      return <div>{common('error')}</div>;
    }
    return <div>{common('loading')}...</div>;
  };

  return (
    <Wrapper apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY!} render={render}>
      <Map center={center}>
        {markers.map(({ icon, infoWindow, position, id }) => (
          <Marker
            key={`${position.lat}-${position.lng}-${id}`}
            icon={icon}
            infoWindow={infoWindow}
            position={position}
            id={id}
          />
        ))}
      </Map>
    </Wrapper>
  );
};

export default GoogleMap;
