import { ITaxonomyTerms } from '@kontent-ai/delivery-sdk';
import { ProdClient } from 'client/client';
import { FeaturedContent } from 'components/modules/FeaturedContent';
import { NarrowTextHero as NarrowTextHeroModule } from 'components/modules/NarrowTextHero';
import { HeadComponent } from 'components/pages/Head';
import OverviewTemplate from 'components/pages/Overview';
// import { PAGINATION_LIMIT } from 'lib/constants';
import cacheData from 'memory-cache';
import { ContentPage, FeaturedSpots, NarrowTextHero as NarrowTextHeroModel, OverviewPage } from 'models';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';
import { alphabeticalSort } from 'utils/alphabeticalSort';
import { onlyDefined } from 'utils/definedInArray';
import { fetchOverviewVariants } from 'utils/getOverviewPage';
import { OptionsProps } from 'utils/overviewFunctionality';

interface NewsOverviewProps {
  ContentPages: ContentPage[];
  NarrowTextHero: NarrowTextHeroModel;
  FeaturedSpot: FeaturedSpots;
  Sector: ITaxonomyTerms[];
  NewsType: ITaxonomyTerms[];
  OverviewPage: OverviewPage;
}

export default function NewsOverview({
  ContentPages,
  NarrowTextHero,
  FeaturedSpot,
  Sector,
  NewsType,
  OverviewPage,
}: NewsOverviewProps) {
  const { multiple, sector: sectorTranslate, content_type: contentTypeTranslate } = withTranslations();
  const translations = withTranslations();
  // console.log('Content page ', ContentPages);
  // ------------------------ FUNCTIONS ------------------------
  // -- Divide pages to show into arrays of 12 pages --
  const dividePagesToShow = () => {
    let pageCollectionNumber = 0;
    // Use this instead of index due to some pages not showing w/ filters
    let currentIndex = 0;
    const pagesToShowTemp: [ContentPage[]] = [[]];
    ContentPages.forEach((page) => {
      if (currentIndex % 10 === 0 && currentIndex !== 0 && pagesToShowTemp[pageCollectionNumber]?.length >= 10) {
        pageCollectionNumber++;
      }
      if (page.elements.tagsSector.value?.length > 0) {
        page.elements.tagsSector.value.forEach((sector) => {
          const sectorCodename = sector?.codename;
          const contentTypeCodename = page.elements.tagsContentType.value[0]?.codename;
          if (
            (filterSector.includes(sectorCodename) || !filterSector[0]) &&
            (filterNewsType.includes(contentTypeCodename) || !filterNewsType[0])
          ) {
            if (!Array.isArray(pagesToShowTemp[pageCollectionNumber])) {
              pagesToShowTemp[pageCollectionNumber] = [];
            }
            const containsValue = pagesToShowTemp[pageCollectionNumber].some(
              (obj) => obj.system.codename === page.system.codename,
            );
            !containsValue &&
              // pagesToShowTemp[pageCollectionNumber]?.length < 10 &&
              pagesToShowTemp[pageCollectionNumber].push(page);
            pagesToShowTemp[pageCollectionNumber].length >= 10 && pageCollectionNumber++;

            //currentIndex++;
          }
        });
      } else {
        const sectorCodename = page.elements.tagsSector.value[0]?.codename;
        const contentTypeCodename = page.elements.tagsContentType.value[0]?.codename;
        if (
          (filterSector.includes(sectorCodename) || !filterSector[0]) &&
          (filterNewsType.includes(contentTypeCodename) || !filterNewsType[0])
        ) {
          if (!Array.isArray(pagesToShowTemp[pageCollectionNumber])) {
            pagesToShowTemp[pageCollectionNumber] = [];
          }
          pagesToShowTemp[pageCollectionNumber]?.length < 10 && pagesToShowTemp[pageCollectionNumber].push(page);
          //currentIndex++;
        }
      }
      currentIndex++;
    });
    //   console.log('pagesToShowTemp ', pagesToShowTemp)

    return onlyDefined(pagesToShowTemp);
  };

  // -- Functions that check amount of pages with filters --

  const updateFilterSector = () => {
    //setPagination(0);
    return Sector.map((sector) => {
      let amountOfPagesWithTag = 0;
      ContentPages.forEach((page) => {
        page.elements.tagsSector.value.forEach((tag) => {
          const sectorCodename = tag?.codename;
          const contentTypeCodeName = page.elements.tagsContentType.value[0]?.codename;
          if (
            sector.codename === sectorCodename &&
            (filterNewsType.includes(contentTypeCodeName) || !filterNewsType[0])
          ) {
            amountOfPagesWithTag++;
          }
        });
      });
      return Object.assign({
        amountOfPagesWithTag: amountOfPagesWithTag,
        ...sector,
        name: sectorTranslate(sector.codename),
      });
    }).sort((a, b) => alphabeticalSort(a.name, b.name));
  };

  const updateFilterNewsType = () => {
    // setPagination(0);
    return NewsType.map((type) => {
      let amountOfPagesWithTag = 0;
      ContentPages.forEach((page) => {
        const sectorCodename = page.elements.tagsSector.value[0]?.codename;
        const contentTypeCodeName = page.elements.tagsContentType.value[0]?.codename;
        if (type.codename === contentTypeCodeName && (filterSector.includes(sectorCodename) || !filterSector[0])) {
          amountOfPagesWithTag++;
        }
      });
      return Object.assign({
        amountOfPagesWithTag: amountOfPagesWithTag,
        ...type,
        name: contentTypeTranslate(type.codename),
      });
    }).sort((a, b) => alphabeticalSort(a.name, b.name));
  };
  // -- Functions that check amount of pages with filters End --

  // -- URL based functions --
  /* const updateFiltersInUrl = () => {
    const removeQueries = router.asPath.includes('?') && router.asPath.split('?').reverse().pop();
    const urlArray =
      removeQueries !== false && removeQueries !== undefined ? removeQueries.split('/') : router.asPath.split('/');
    urlArray.length >= 3 && urlArray.pop();
    urlArray.push(...filterNewsType);
    const urlString = filterSector[0] ? urlArray.join('/') + `?location=${filterSector}` : urlArray.join('/');

    // This crashes the page if it's on localhost

    router.replace(urlString, undefined, {shallow: true})
  }; */

  /* const getLocationFilterFromUrl = () => {
    return NewsType.filter((e) => {
      return e.codename === router.asPath.split('location=').slice(-1)[0];
    }).length;
  }; */
  // -- URL based functions End --
  // ------------------------ FUNCTIONS END ------------------------

  // ------------------------ VARIABLES ------------------------
  const [filterSector, setfilterSector] = useState<string[]>([]);
  const [filterNewsType, setFilterNewsType] = useState<string[]>([]);
  const [amountOfFilters, setAmountOfFilters] = useState(0);
  const [pagesToShow, setPagesToShow] = useState<ContentPage[][]>(dividePagesToShow());
  const [SectorState, setSectorState] = useState<OptionsProps[]>([]);
  const [newsTypeState, setNewsTypeState] = useState<OptionsProps[]>([]);

  // ------------------------ VARIABLES END ------------------------

  // ------------------------ USE EFFECTS ------------------------
  useEffect(() => {
    const newSector = updateFilterSector();
    const newNewsType = updateFilterNewsType();
    setSectorState(newSector);
    setNewsTypeState(newNewsType);

    setPagesToShow(dividePagesToShow());
  }, []);

  const router = useRouter();
  useEffect(() => {
    const newSector = updateFilterSector();
    const newNewsType = updateFilterNewsType();
    setSectorState(newSector);
    setNewsTypeState(newNewsType);

    setPagesToShow(dividePagesToShow());
  }, [router.locale, router.isReady, translations.isReady]);

  // -- Runs when filters change
  useEffect(() => {
    setAmountOfFilters(filterNewsType.length + filterSector.length);

    const newSector = updateFilterSector();
    const newNewsType = updateFilterNewsType();
    setSectorState(newSector);
    setNewsTypeState(newNewsType);

    setPagesToShow(dividePagesToShow());
  }, [filterNewsType, filterSector]);
  // console.log('filterSector ',filterSector)
  // console.log('pagesToShow ',pagesToShow)

  // ------------------------ USE EFFECTS END ------------------------

  return (
    <>
      <HeadComponent page={OverviewPage[0]} />

      {!!NarrowTextHero && <NarrowTextHeroModule model={NarrowTextHero} />}
      <OverviewTemplate
        PagesToShow={pagesToShow}
        overviewTitle={multiple('news')}
        amountOfFilters={amountOfFilters}
        filterTwo={{
          value: filterSector,
          set: setfilterSector,
          state: SectorState,
          label: 'sector',
        }}
        filterThree={{
          value: filterNewsType,
          set: setFilterNewsType,
          state: newsTypeState,
          label: 'content_type',
        }}
      />
      {!!FeaturedSpot && <FeaturedContent model={FeaturedSpot} />}
    </>
  );
}

export const getStaticNewsProps = async ({ locale }: { locale: string }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const ContentPages = await ProdClient.items<ContentPage>()
    .type('content_page')
    .containsFilter('elements.category', ['news'])
    .languageParameter(languageCodename)
    .equalsFilter('system.language', languageCodename)
    .orderByDescending('elements.summary_date')
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items.sort((a, b) =>
        Date.parse(a.elements.summaryDate.value ?? a.system.lastModified) <
        Date.parse(b.elements.summaryDate.value ?? b.system.lastModified)
          ? 1
          : -1,
      ) as ContentPage[];
      // .sort((a, b) =>
      //   (a.elements.featured.value[0]?.codename ? a.elements.featured.value[0].codename : 'no') >
      //   (b.elements.featured.value[0]?.codename ? b.elements.featured.value[0]?.codename : 'no')
      //     ? -1
      //     : 1,
      // ) as ContentPage[];
    });

  const OverviewPage = await ProdClient.items<OverviewPage>()
    .type('overview_page')
    .containsFilter('elements.category', ['news'])
    .languageParameter(languageCodename)
    .elementsParameter([
      'featured',
      'category',
      'url',
      'summary__title',
      'summary__description',
      'summary__image_frontify',
      'part_of_campaign_page',
      'summary__date',
      'tags__content_type',
      'tags__sector',
    ])
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items;
    });

  const FeaturedSpot =
    !!OverviewPage.length &&
    !!OverviewPage[0].elements.hero?.value[0] &&
    (await ProdClient.item<FeaturedSpots>(OverviewPage[0].elements.hero.value[0])
      .languageParameter(languageCodename)
      .depthParameter(2)
      .toPromise()
      .then((response) => {
        return response.data.item as FeaturedSpots;
      }));

  const NarrowTextHero =
    !!OverviewPage.length &&
    !!OverviewPage[0].elements.newsHero?.value[0] &&
    (await ProdClient.item<NarrowTextHeroModel>(OverviewPage[0].elements.newsHero.value[0])
      .languageParameter(languageCodename)
      .toPromise()
      .then((response) => {
        return response.data.item as NarrowTextHeroModel;
      }));

  const NewsType =
    cacheData.get('taxonomy_content_type') == null
      ? await ProdClient.taxonomy('content_type')
          .toPromise()
          .then((response) => {
            cacheData.put('taxonomy_content_type', response.data.taxonomy.terms, 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('taxonomy_content_type'); //to clear the existing cache after 24hr
            });
            return response.data.taxonomy.terms;
          })
      : cacheData.get('taxonomy_content_type');

  const Sector =
    cacheData.get('taxonomy_sector') == null
      ? await ProdClient.taxonomy('sector')
          .toPromise()
          .then((response) => {
            cacheData.put('taxonomy_sector', response.data.taxonomy.terms, 86400000, function () {
              console.log('clearing the cache');
              cacheData.del('taxonomy_sector'); //to clear the existing cache after 24hr
            });
            return response.data.taxonomy.terms;
          })
      : cacheData.get('taxonomy_sector');

  const variants = await fetchOverviewVariants('news');

  return {
    ContentPages: ContentPages,
    FeaturedSpot: FeaturedSpot,
    Sector: Sector,
    NewsType: NewsType,
    NarrowTextHero: NarrowTextHero,
    OverviewPage: OverviewPage,
    variants,
  };
};
