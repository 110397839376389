import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Text } from '@chakra-ui/react';
import axios from 'axios';
import { ProdClient } from 'client/client';
import { Button } from 'components/base/Button';
import { Filter } from 'components/base/Filter';
import { IconComp } from 'components/base/IconComp';
import { Input } from 'components/base/Input';
import { MultipleChoiceFilter } from 'components/base/MultipleChoiceFilter';
import { PaginationNew } from 'components/base/PaginationNew';
import { TalentCommunitiesCTA } from 'components/modules/TalentCommunitiesCTA';
import { HeadComponent } from 'components/pages/Head';
import { toTitleCase } from 'helpers/textCase';
import { getTranslations } from 'helpers/translations';
import { VacanciesOverview } from 'models/content-types';
import { useRouter } from 'next/router';
import { formatStates } from 'pages/api/jobsPosts';
import { useEffect, useRef, useState } from 'react';
import slugify from 'slugify';
import { withTranslations } from 'store/translations';
import { fetchAccessToken } from 'utils/fetchAccessToken';
import { getHost } from 'utils/getHost';
import { intoCodename } from 'utils/normalization/careerFilters';
import { getCareersVariants } from 'utils/overviewVariants';
import { shouldForceDefaultLocale } from 'utils/withForceLocale';

import EmptyContent from '../../../../../public/images/empty-content.svg';

type OptionsState = {
  apiCodename: string;
  name: string;
  amountOfOffersWithTag: number;
};

interface CareersOverviewProps {
  Jobs: any;
  VacanciesOverview: VacanciesOverview;
  initialFilters: {
    locationQueryName: string;
    workplaceQueryName: string;
    marketQueryName: string;
    careerStageQueryName: string;
    searchFilterQueryName: string;
  };
}

type FilterConditions = {
  country: string[];
  workplace: string;
  market: string;
  careerStage: string;
  refNumber: string;
};

export default function CareersOverview({ Jobs, VacanciesOverview, initialFilters }: CareersOverviewProps) {
  const router = useRouter();
  useEffect(() => {
    if (Jobs === null) {
      router.push('/500');
    }
  }, []);

  const shouldUseDefaultLocale = shouldForceDefaultLocale(router);
  const { aria, common, multiple, workplace, market, career_stage, location } = withTranslations();

  const host = typeof window !== 'undefined' ? getHost(window) : '';

  // ----------------------- FUNCTIONS -----------------------
  const fetchJobPosts = async (
    country = [''],
    workplace = '',
    market = '',
    careerStage = '',
    search = '',
    pageNr: number,
  ) => {
    // If there's already a previous call then abort it
    if (previousController.current) previousController.current.abort();

    // Create a new controller and save it to our useRef (previousController)
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;

    // This timeout stops the call from happening if it exceeds 20 seconds
    const timeoutFunc = setTimeout(() => {
      controller.abort();
    }, 20000);

    // Fetch to local API
    const data = await fetch(
      `${host}/api/jobsPosts?locale=${router.locale}&countries=${country?.join(
        '_SEPARATOR_',
      )}&workplace=${workplace}&market=${market}&careerStage=${careerStage}&search=${search}&pageNumber=${pageNr}`,
      { signal: signal },
    )
      .then((res) => {
        // Clear abort timer
        clearTimeout(timeoutFunc);
        return res.json();
      })
      .catch(() => null);
    if (data) {
      setPostJobsTemp(data.jobsPosts);
      didFiltersLoad && setIsLoading(false);
    }
  };

  const updateFiltersFromUrl = (queryName: string, state?: OptionsState[]): string[] | undefined => {
    const extractSearchParamsFromUrl = (asPath: string) => {
      const queryString = asPath.split('?')[1];
      const urlParams = new URLSearchParams(queryString);

      const params = {} as { [key: string]: string[] };

      for (const [key, value] of urlParams.entries()) {
        params[key] = value.split(',');
      }

      return params;
    };
    const query = extractSearchParamsFromUrl(router.asPath);
    return query[queryName]
      ?.map((item) => state?.find((state) => slugify(state.name, { lower: true }) === item)?.apiCodename)
      .filter(Boolean) as string[];
  };
  // ----------------------- FUNCTIONS END -----------------------

  // ----------------------- VARIABLES -----------------------
  const previousController = useRef<AbortController>();
  const searchNoRenderRun = useRef(false);
  const filterNoRenderRun = useRef(false);
  const [filterLocation, setFilterLocation] = useState<string[]>();
  const [filterWorkplace, setFilterWorkplace] = useState<string[]>();
  const [filterMarket, setFilterMarket] = useState<string[]>();
  const [filterCareerStage, setFilterCareerStage] = useState<string[]>();
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [postJobsTemp, setPostJobsTemp] = useState<any>(Jobs);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [didFiltersLoad, setDidFiltersLoad] = useState(false);

  const [countryState, setCountryState] = useState<OptionsState[]>([]);
  const [marketState, setMarketState] = useState<OptionsState[]>([]);
  const [workplaceState, setWorkplaceState] = useState<OptionsState[]>([]);
  const [careerStageState, setCareerStageState] = useState<OptionsState[]>([]);
  // const [searchState, setSearchStageState] = useState<OptionsState[]>([]);

  const [amountOfFilters, setAmountOfFilters] = useState(0);
  const [pagination, setPagination] = useState(0);
  const showingAmountOfPagesRef = useRef<HTMLDivElement>(null);

  const [timerId, setTimerId] = useState<NodeJS.Timeout>();

  const updateStates = () => {
    // Set options
    setCountryState(postJobsTemp.countries);
    setMarketState(postJobsTemp.markets);
    setWorkplaceState(postJobsTemp.locations);
    setCareerStageState(postJobsTemp.careerStages);
  };
  // ----------------------- VARIABLES END -----------------------

  // ----------------------- USEEFFECTS -----------------------

  useEffect(() => {
    if (Jobs && didFiltersLoad) {
      if (!searchNoRenderRun.current) {
        searchNoRenderRun.current = true;
        return;
      }
      setIsLoading(true);
      clearTimeout(timerId);
      const id = setTimeout(() => {
        fetchJobPosts(
          filterLocation,
          filterWorkplace?.[0],
          filterMarket?.[0],
          filterCareerStage?.[0],
          searchFilter,
          pagination + 1,
        );
      }, 500);
      setTimerId(id);
    }
  }, [didFiltersLoad]);

  useEffect(() => {
    if (Jobs && didFiltersLoad) {
      updateStates();
      setIsLoading(false);
    }
  }, [postJobsTemp, didFiltersLoad]);

  useEffect(() => {
    if (Jobs) {
      if (!filterNoRenderRun.current) {
        filterNoRenderRun.current = true;
        return;
      }
      setPagination(0);
      fetchJobPosts(
        filterLocation,
        filterWorkplace?.[0],
        filterMarket?.[0],
        filterCareerStage?.[0],
        searchFilter,
        pagination + 1,
      );
      const ifSearchFilter = searchFilter !== '' && searchFilter ? 1 : 0;
      setAmountOfFilters(
        (filterLocation?.length || 0) +
          (filterWorkplace?.length || 0) +
          (filterMarket?.length || 0) +
          (filterCareerStage?.length || 0) +
          ifSearchFilter,
      );
      setIsLoading(true);
    }
  }, [filterLocation, filterWorkplace, filterMarket, filterCareerStage, searchFilter, router.locale, didFiltersLoad]);

  // Setting the filters in url
  useEffect(() => {
    if (!router.isReady) return;
    const slugifyMultiple = (query: string, state: OptionsState[], filter?: string[]) => {
      const values = state
        .filter((item) => filter?.includes(item.apiCodename))
        .map((item) => slugify(item.name, { lower: true }));
      return values.length
        ? `${slugify(multiple(query, null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }), {
            lower: true,
          })}=${values}`
        : '';
    };
    const locationQuery = slugifyMultiple('careerLocation', countryState, filterLocation);
    const workplaceQuery = slugifyMultiple('careerWorkplace', workplaceState, filterWorkplace);
    const marketQuery = slugifyMultiple('market', marketState, filterMarket);
    const careerStageQuery = slugifyMultiple('careerStage', careerStageState, filterCareerStage);
    const slugifiedInput = slugify(searchFilter, { lower: true, replacement: '-', remove: /[*+~.()'"!:@]/g });
    const searchQuery = slugifiedInput !== null && slugifiedInput !== '' ? `free-text=${slugifiedInput}` : '';
    const urlRoot = slugify(multiple('career'), { lower: true, remove: /[*+~.()'"!:@]/g });
    const urlString = `${[locationQuery, workplaceQuery, marketQuery, careerStageQuery, searchQuery]
      .filter(Boolean)
      .join('&')}`;

    if (urlString) {
      router.replace(urlRoot + `?${urlString}`, undefined, { shallow: true, scroll: false });
    } else {
      router.replace(urlRoot, undefined, { shallow: true, scroll: false });
    }
    //urlString && router.replace(urlRoot + `?${urlString}`, undefined, { shallow: true, scroll: false });
  }, [countryState, workplaceState, marketState, careerStageState, searchFilter]);

  // Setting initial url filters
  useEffect(() => {
    setIsLoading(true);
    if (!router.isReady) return;
    const locations = updateFiltersFromUrl(initialFilters.locationQueryName, Jobs.countries);
    const workplace = updateFiltersFromUrl(initialFilters.workplaceQueryName, Jobs.locations);
    const market = updateFiltersFromUrl(initialFilters.marketQueryName, Jobs.markets);
    const careerStage = updateFiltersFromUrl(initialFilters.careerStageQueryName, Jobs.careerStages);
    const filterSearch = Array.isArray(router.query['free-text'])
      ? router.query['free-text'].join(', ')
      : router.query['free-text'] || ''; // Convert array to string if necessary, or use an empty string if undefined
    const formattedFilterSearch = filterSearch;
    setFilterLocation(locations);
    setFilterWorkplace(workplace);
    setFilterMarket(market);
    setFilterCareerStage(careerStage);
    setDidFiltersLoad(true);
    setSearchFilter(formattedFilterSearch);
  }, [router.isReady]);

  // ----------------------- USEEFFECTS END -----------------------
  return (
    Jobs && (
      <>
        <HeadComponent page={VacanciesOverview}></HeadComponent>
        {/* {router.locale == 'fi-fi' && (
          <div
            id='sr-plugins'
            data-configuration-id={process.env.JOB_ALERT_CONFIGURATION_ID_FI}
            data-bg='009DF0'
            data-lang={process.env.JOB_ALERT_DATA_LANG_FI}
            data-company-identifier='Ramboll3'
          ></div>
        )}
        {router.locale == 'no-no' && (
          <div
            id='sr-plugins'
            data-configuration-id={process.env.JOB_ALERT_CONFIGURATION_ID_NO}
            data-bg='009DF0'
            data-lang={process.env.JOB_ALERT_DATA_LANG_NO}
            data-company-identifier='Ramboll3'
          ></div>
        )}
        {router.locale == 'sv-se' && (
          <div
            id='sr-plugins'
            data-configuration-id={process.env.JOB_ALERT_CONFIGURATION_ID_SV}
            data-bg='009DF0'
            data-lang={process.env.JOB_ALERT_DATA_LANG_SV}
            data-company-identifier='Ramboll3'
          ></div>
        )}
        {router.locale == 'de-de' && (
          <div
            id='sr-plugins'
            data-configuration-id={process.env.JOB_ALERT_CONFIGURATION_ID_DE}
            data-bg='009DF0'
            data-lang={process.env.JOB_ALERT_DATA_LANG_DE}
            data-company-identifier='Ramboll3'
          ></div>
        )}
        {(router.locale == 'en' ||
          router.locale == 'en-us' ||
          router.locale == 'en-apac' ||
          router.locale == 'en-gb' ||
          router.locale == 'da-dk') && (
          <div
            id='sr-plugins'
            data-configuration-id={process.env.JOB_ALERT_CONFIGURATION_ID_DEFAULT}
            data-bg='009DF0'
            data-lang={process.env.JOB_ALERT_DATA_LANG_DEFAULT}
            data-company-identifier='Ramboll3'
          ></div>
        )} */}
        <Box w='100%'>
          <Box pr={{ base: 'xs', lg: 'l' }} pl={{ base: 'xs', lg: 'l' }} maxW='1440px' margin='auto'>
            <Text as='h1' textStyle={{ base: 'mobile.h4', lg: 'desktop.h4' }} pt='m' pb='s'>
              {common('openPositions', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
            </Text>
            {/* ----------------------- FILTERS & SEARCH ----------------------- */}
            <Box pb='s'>
              <Input
                label={common('searchForPositions', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
                // For some reason, it cannot find "search" in the english.json locale file
                placeholder={aria('search', null, {
                  ...(shouldUseDefaultLocale && { ns: 'english', fallback: 'Search' }),
                })}
                type='search'
                value={searchFilter}
                onWhite
                onChangeFunc={(value) => setSearchFilter(value)}
              />
            </Box>
            <Flex alignItems='flex-start' position='relative' flexDirection={{ base: 'column', lg: 'row' }}>
              <Flex
                flexDirection={{ base: 'column', lg: 'row' }}
                flexWrap='wrap'
                alignContent='center'
                w={{ base: '100%', lg: 'calc(100% - 180px)' }}
                position='relative'
                pb={{ base: 's', lg: 'm' }}
                rowGap={{ lg: 'xs' }}
              >
                <MultipleChoiceFilter
                  label={'careerLocation'}
                  placeholder={common('all', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
                  disabled={false}
                  options={countryState}
                  currentFilters={filterLocation?.map((item) => intoCodename(item))}
                  clearAll={() => setFilterLocation([])}
                  onWhite={true}
                  onCheckFunc={(value: string) => setFilterLocation([...(filterLocation ? filterLocation : []), value])}
                  onUnCheckFunc={(value: string) => {
                    setFilterLocation(
                      filterLocation?.filter((item) => {
                        return item !== value;
                      }),
                    );
                  }}
                />
                <Filter
                  label={'careerWorkplace'}
                  placeholder={common('all', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
                  disabled={false}
                  options={workplaceState}
                  currentFilter={filterWorkplace}
                  onWhite={true}
                  onChangeFunc={(value: string) => {
                    // '' means pressing "All"
                    if (value !== '') {
                      setFilterWorkplace([value]);
                    } else {
                      setFilterWorkplace([]);
                    }
                  }}
                />
                <Filter
                  label={'market'}
                  placeholder={common('all', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
                  disabled={false}
                  options={marketState}
                  currentFilter={filterMarket}
                  onWhite={true}
                  onChangeFunc={(value: string) => {
                    // '' means pressing "All"
                    if (value !== '') {
                      setFilterMarket([value]);
                    } else {
                      setFilterMarket([]);
                    }
                  }}
                />
                <Filter
                  label={'careerStage'}
                  placeholder={common('all', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
                  disabled={false}
                  options={careerStageState}
                  currentFilter={filterCareerStage}
                  onWhite={true}
                  onChangeFunc={(value: string) => {
                    // '' means pressing "All"
                    if (value !== '') {
                      setFilterCareerStage([value]);
                    } else {
                      setFilterCareerStage([]);
                    }
                  }}
                />
              </Flex>
              <Flex
                color='cyan.web'
                display={amountOfFilters !== 0 ? 'unset' : 'none'}
                textStyle={'desktop.body.medium.s'}
                position='relative'
                pb='s'
                alignSelf={{ base: 'flex-end', lg: 'unset' }}
                onClick={() => {
                  setFilterLocation([]);
                  setFilterWorkplace([]);
                  setFilterMarket([]);
                  setFilterCareerStage([]);
                  setSearchFilter('');
                }}
              >
                <Text as='button' position='relative' top={{ base: 0, lg: '40px' }} textStyle='desktop.body.medium.s'>
                  {common('clearFilters', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}{' '}
                  {`(${amountOfFilters})`}
                </Text>
              </Flex>
            </Flex>
            {/* ----------------------- FILTERS & SEARCH END ----------------------- */}

            {/* -----------------------  SHOWING X OF Y RESULTS ----------------------- */}
            {postJobsTemp.totalRecordCount !== 0 ? (
              <Box
                ref={showingAmountOfPagesRef}
                suppressHydrationWarning
                textStyle={'desktop.body.book.s'}
                pb={{ base: 's', lg: 'm' }}
                aria-label={aria(
                  'showingPages',
                  {
                    itemsShown: `${10 * pagination + 1} to ${postJobsTemp.data.jobPosts.length + pagination * 10}`,
                    total: postJobsTemp.totalRecordCount,
                  },
                  { ...(shouldUseDefaultLocale && { ns: 'english' }) },
                )}
                dangerouslySetInnerHTML={{
                  __html: common(
                    'showingResults',
                    {
                      itemsShown: `${10 * pagination + 1} -
              ${postJobsTemp.data.jobPosts.length + pagination * 10}`,
                      total: postJobsTemp.totalRecordCount,
                    },
                    { ...(shouldUseDefaultLocale && { ns: 'english' }) },
                  ),
                }}
              />
            ) : (
              <Box
                suppressHydrationWarning
                textStyle={'desktop.body.book.s'}
                pb={{ base: 's', lg: 'm' }}
                dangerouslySetInnerHTML={{
                  __html: common('showingNoResults', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }),
                }}
              />
            )}
            {/* -----------------------  SHOWING X OF Y RESULTS END ----------------------- */}

            {/* ----------------------- POSITIONS ----------------------- */}
            {postJobsTemp.data.jobPosts.length > 0 ? (
              <Accordion allowToggle pb='xl' position='relative'>
                <Box
                  w='100%'
                  h='100%'
                  background='white'
                  opacity={0.9}
                  zIndex={2}
                  position='absolute'
                  textAlign='center'
                  textStyle='desktop.h4'
                  display={isLoading ? 'block' : 'none'}
                ></Box>
                {postJobsTemp.data.jobPosts.map((offer, index) => {
                  const firstParagraph =
                    offer.description.split('<p>')[1]?.split('</p>')[0].length > 50
                      ? offer.description.split('<p>')[1]?.split('</p>')[0]
                      : offer.description.split('<p>')[1]?.split('</p>')[0] +
                        '<br />' +
                        offer.description.split('<p>')[2]?.split('</p>')[0];

                  return (
                    <AccordionItem
                      key={index}
                      w='100%'
                      borderBottom='1px solid'
                      borderBottomColor='mountain.20'
                      pt='0'
                      _hover={{ backgroundColor: 'pebble.10' }}
                    >
                      {({ isExpanded }) => (
                        <Box bg={isExpanded ? 'pebble.10' : 'unset'} px='xs' pb={{ base: 'xxs', lg: 'xs' }}>
                          {/* ----- TITLE AND BUTTON ----- */}
                          <AccordionButton display='flex' flexDirection='column' alignItems='flex-start'>
                            <Flex w='100%' order='2' alignItems='center' justifyContent='space-between'>
                              <Text
                                as='h3'
                                color={isExpanded ? 'cyan.web' : 'mountain'}
                                textStyle={{ base: 'mobile.h4', lg: 'desktop.h5' }}
                                textAlign='left'
                              >
                                {offer.title}
                              </Text>
                              <IconComp styleName='active' iconName={isExpanded ? 'minus' : 'plus'} />
                            </Flex>

                            <Text order='1' pt='xs' pb='xs' textAlign='left'>
                              {offer.isGraduateCareers === 'Y'
                                ? `${career_stage('early_careers', null, {
                                    ...(shouldUseDefaultLocale && { ns: 'english' }),
                                  })}`
                                : offer.isRambollCareers == 'Y'
                                ? `${career_stage('experienced', null, {
                                    ...(shouldUseDefaultLocale && { ns: 'english' }),
                                  })}`
                                : ''}
                              {/* Fallback used only for locations if and until editors add locations to english namespace */}
                              {offer.location &&
                                ' | ' +
                                  toTitleCase(
                                    workplace(intoCodename(offer.location), null, {
                                      fallback: offer.location,
                                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                                    }),
                                  )}
                              {offer.country &&
                                ' | ' +
                                  toTitleCase(
                                    location(intoCodename(offer.country), null, {
                                      fallback: offer.country,
                                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                                    }),
                                  )}
                              {offer.market &&
                                ' | ' +
                                  toTitleCase(
                                    market(intoCodename(offer.market), null, {
                                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                                    }),
                                  )}
                            </Text>
                          </AccordionButton>
                          {/* ----- TITLE AND BUTTON END ----- */}

                          {/* ----- BODY TEXT ----- */}
                          <AccordionPanel bg={isExpanded ? 'pebble.10' : 'unset'}>
                            <Box
                              suppressHydrationWarning
                              textStyle={{ base: 'desktop.body.book.s', lg: 'desktop.body.s' }}
                              sx={{
                                p: {
                                  paddingBottom: '20px',
                                },
                                'p:has(strong)': {
                                  paddingBottom: '0',
                                },
                                ul: {
                                  paddingBottom: '40px',
                                  listStyleType: 'disc',
                                  listStylePosition: 'inside',
                                },
                                li: {},
                              }}
                              dangerouslySetInnerHTML={{ __html: firstParagraph }}
                            ></Box>
                            <Box pt='xs'>
                              <Button
                                as='a'
                                href={`${slugify(multiple('career'), { lower: true })}/${offer.jobPostId}`}
                                size='medium'
                              >
                                {common('goToJob', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
                              </Button>
                            </Box>
                          </AccordionPanel>
                          {/* ----- BODY TEXT END ----- */}
                        </Box>
                      )}
                    </AccordionItem>
                  );
                })}
              </Accordion>
            ) : (
              /* ----------------------- NO RESULTS ----------------------- */
              <Flex pb='l'>
                <Box w={{ base: '100%', lg: '40%' }} display='block'>
                  <Text textStyle={{ base: 'mobile.h4', lg: 'desktop.h5' }} pb='s'>
                    {common('filterDidntMatch', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) })}
                  </Text>
                  <Text textStyle={'desktop.body.light.s'} pb='s'>
                    {common('filterDidntMatchClearFilters', null, {
                      ...(shouldUseDefaultLocale && { ns: 'english' }),
                    })}
                  </Text>
                </Box>
                <Box w='60%' display={{ base: 'none', lg: 'unset' }}>
                  <EmptyContent />
                </Box>
              </Flex>
              /* ----------------------- NO RESULTS END ----------------------- */
            )}
            {/* ----------------------- POSITIONS END ----------------------- */}
          </Box>

          {/* ----------------------- PAGINATION ----------------------- */}
          <Box
            pb='xl'
            display={postJobsTemp.totalRecordCount > 1 ? 'inline-block' : 'none'}
            position='relative'
            left='50%'
            transform='translateX(-50%)'
          >
            <PaginationNew
              total={postJobsTemp.totalRecordCount}
              perPage={10}
              current={pagination + 1}
              onNavigate={(page) => {
                setIsLoading(true);
                setPagination(page - 1);
                fetchJobPosts(
                  filterLocation,
                  filterWorkplace?.[0],
                  filterMarket?.[0],
                  filterCareerStage?.[0],
                  searchFilter,
                  page,
                );
              }}
            />
          </Box>
          {/* ----------------------- PAGINATION END ----------------------- */}

          <TalentCommunitiesCTA options={VacanciesOverview.elements.talentCommunityModule.linkedItems} />
        </Box>
      </>
    )
  );
}

export const getStaticCareersProps = async ({ locale }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const fetchJobs = async (filterConditions: FilterConditions) => {
    const controller = new AbortController();
    const timeoutFunc = setTimeout(() => {
      controller.abort();
      return null;
    }, 20000);
    const fetchedAccessToken = await fetchAccessToken(process.env.JOBS_API_ACCESS_TOKEN_FETCH_SCOPE);
    return await axios
      .get(`${process.env.JOBS_API_URL}?pageNumber=1&pageSize=10`, {
        signal: controller.signal,
        data: {
          filterConditions,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${fetchedAccessToken.data.access_token}`,
        },
      })
      .then((res) => {
        clearTimeout(timeoutFunc);
        return res.data;
      });
  };

  const Jobs = await fetchJobs({} as FilterConditions).catch(() => {
    return null;
  });

  const variants = await getCareersVariants({});

  const vacanciesOverview = await ProdClient.items<VacanciesOverview>()
    .type('vacancies_overview')
    .languageParameter(languageCodename)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((res) => {
      return res.data.items[0];
    });

  const shouldUseDefaultLocale = languageCodename === 'da-dk';
  const {
    location: locationTranslate,
    workplace: workplaceTranslate,
    market: marketTranslate,
    career_stage: careerStageTranslate,
    multiple,
  } = await getTranslations(languageCodename);

  Jobs.countries = formatStates(Jobs.countries, locationTranslate, shouldUseDefaultLocale);
  Jobs.locations = formatStates(Jobs.locations, workplaceTranslate, shouldUseDefaultLocale);
  Jobs.markets = formatStates(Jobs.markets, marketTranslate, shouldUseDefaultLocale);
  Jobs.careerStages = formatStates(Jobs.careerStages, careerStageTranslate, shouldUseDefaultLocale);
  return {
    Jobs: Jobs,
    variants,
    VacanciesOverview: vacanciesOverview,
    initialFilters: {
      locationQueryName: slugify(
        multiple('careerLocation', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }),
        { lower: true },
      ),
      marketQueryName: slugify(multiple('market', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }), {
        lower: true,
      }),
      careerStageQueryName: slugify(
        multiple('careerStage', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }),
        { lower: true },
      ),
      workplaceQueryName: slugify(
        multiple('careerWorkplace', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }),
        { lower: true },
      ),
      searchFilterQueryName: slugify(
        multiple('careerSearch', null, { ...(shouldUseDefaultLocale && { ns: 'english' }) }),
        { lower: true },
      ),
    },
  };
};
