import {
  AspectRatio,
  Box,
  Card,
  CardBody,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { IconComp } from 'components/base/IconComp';
import GoogleMap from 'components/modules/GoogleMap';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { withTranslations } from 'store/translations';

import { Coordinates, Marker } from './types';

type OfficesMapProps = {
  markers: Marker[];
};

type SelectedOffices = ('head' | 'local' | 'project')[];

const OfficesMap = ({ markers }: OfficesMapProps) => {
  const { aria, common } = withTranslations();
  const [userLocation, setUserLocation] = useState<Coordinates>();
  const [searchValue, setSearchValue] = useState('');
  const [selectedOffices, setSelectedOffices] = useState<SelectedOffices>(['head', 'local', 'project']);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      setUserLocation({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    });
  }, []);

  const filteredMarkers = useMemo(
    () =>
      markers.filter(
        ({ city, country, type }) =>
          (city?.toLowerCase().includes(searchValue.toLowerCase()) ||
            country?.toLowerCase().includes(searchValue.toLowerCase())) &&
          selectedOffices.includes(type),
      ),
    [markers, searchValue, selectedOffices],
  );

  const handleOnSearchChange = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const handleOnCheckboxChange = (value: SelectedOffices) => setSelectedOffices(value);

  return (
    <Box position='relative'>
      <Card
        bg='white'
        border='0'
        borderRadius='0'
        left={{ base: '3xs', md: 'xxs', lg: 's' }}
        position='absolute'
        top={{ base: '3xs', md: 'xxs', lg: 'xs' }}
        variant='elevated'
        zIndex={1}
      >
        <CardBody>
          <Text color='mountain.base' pb='3xs' textStyle='mobile.body.xs'>
            {common('searchByCountryCity')}
          </Text>
          <InputGroup mb='xs' size={{ base: 'sm', md: 'md', lg: 'lg' }}>
            <Input
              bg='gray.98'
              borderColor='mountain.20'
              placeholder={aria('search')}
              value={searchValue}
              onChange={handleOnSearchChange}
            />
            <InputRightElement top='50%' right='xxs' transform='translateY(-50%)'>
              <IconComp
                chakraStyle={{
                  h: { base: '16px', md: '22px', lg: '28px' },
                  w: { base: '16px', md: '22px', lg: '28px' },
                }}
                iconName='search'
              />
            </InputRightElement>
          </InputGroup>
          <CheckboxGroup value={selectedOffices} onChange={handleOnCheckboxChange}>
            <Stack direction={{ base: 'row', lg: 'column' }} spacing={{ base: 'xs', md: 'm', lg: 'xxs' }}>
              <Checkbox value='head'>
                <Flex align='center' as='span' gap='3xs'>
                  <IconComp iconName='officeMain' />
                  <Text color='mountain.base' display={{ base: 'none', lg: 'block' }} textStyle='desktop.body.medium.s'>
                    {common('countryHeadOffice')}
                  </Text>
                </Flex>
              </Checkbox>
              <Checkbox value='local'>
                <Flex align='center' as='span' gap='3xs'>
                  <IconComp iconName='officeLocal' />
                  <Text color='mountain.base' display={{ base: 'none', lg: 'block' }} textStyle='desktop.body.medium.s'>
                    {common('localOffice')}
                  </Text>
                </Flex>
              </Checkbox>
              <Checkbox value='project'>
                <Flex align='center' as='span' gap='3xs'>
                  <IconComp iconName='featuredProject' />
                  <Text color='mountain.base' display={{ base: 'none', lg: 'block' }} textStyle='desktop.body.medium.s'>
                    {common('featuredProject')}
                  </Text>
                </Flex>
              </Checkbox>
            </Stack>
          </CheckboxGroup>
        </CardBody>
      </Card>
      <AspectRatio ratio={{ base: 6 / 9, md: 1, lg: 14 / 9 }}>
        <GoogleMap center={userLocation} markers={filteredMarkers} />
      </AspectRatio>
    </Box>
  );
};

export default OfficesMap;
