import { nodeParser } from '@kontent-ai/delivery-node-parser';
import { createRichTextHtmlResolver } from '@kontent-ai/delivery-sdk';
import { ProdClient } from 'client/client';
import GooleMapInfoWindow, { GoogleMapInfoWindowProps } from 'components/modules/GoogleMapInfoWindow';
import MapPage from 'components/pages/ContactUs/MapPage';
import { Marker } from 'components/pages/ContactUs/types';
import { ContentPage as ContentPageModel, Office as OfficeModel, OfficeOverviewMetadata } from 'models';
import getT from 'next-translate/getT';
import { renderToString } from 'react-dom/server';
import getNormalizedGeneralOfficeData from 'utils/normalization/generalOffice';
import { getContactUsVariants } from 'utils/overviewVariants';

const getOfficePage = async (countryCodename: string, locale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE!) => {
  return await ProdClient.items<ContentPageModel>()
    .type('content_page')
    .containsFilter('elements.tags__location', [countryCodename])
    .containsFilter('elements.category', ['office'])
    .elementsParameter(['url'])
    .limitParameter(1)
    .languageParameter(locale)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((res) => res.data.items[0]?.elements.url.value ?? '')
    .catch(() => '');
};

const getPosition = (pin: string) => {
  const coords = pin
    .match(/[+-]?\d+(\.\d+)?/g)
    ?.map((coordinate) => parseFloat(coordinate))
    .filter((coordinate) => Number.isFinite(coordinate));

  if (coords?.[0] && coords?.[1]) {
    return {
      lat: coords[0],
      lng: coords[1],
    };
  }
  return null;
};

const filterWithValidCoordinates = ({ elements }: OfficeModel | ContentPageModel) =>
  !!getPosition(elements.googlePin.value);

// We don't want to inject global styles for every Info Window because we already have them in `<head>`
// We still inject repetitive styles for each window which is a bit redundant, but manually removing them
// and adding in global `<head>` would be extra work prone to bugs, so for now we have boilerplate CSS
const getInfoWindowString = (props: GoogleMapInfoWindowProps) =>
  renderToString(<GooleMapInfoWindow {...props} />).replace(/<style data-emotion="css-global.*?<\/style>/g, '');

export const getStaticMapProps = async ({ locale }: { locale: string }) => {
  const common = await getT(locale, 'common');
  const multiple = await getT(locale, 'multiple');
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  const [
    {
      data: { items: officesModel },
    },
    {
      data: { items: featuredProjectsModel },
    },
  ] = await Promise.all([
    ProdClient.items<OfficeModel>()
      .languageParameter(languageCodename)
      .type('office')
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise(),
    ProdClient.items<ContentPageModel>()
      .type('content_page')
      .containsFilter('elements.category', ['project'])
      .containsFilter('elements.featured', ['yes'])
      .languageParameter(languageCodename)
      .equalsFilter('system.language', languageCodename)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise(),
  ]);
  const variants = await getContactUsVariants('mapUrl');

  const officeOverviewMetadata = await ProdClient.items<OfficeOverviewMetadata>()
    .type('office_overview_metadata')
    .languageParameter(languageCodename)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items[0];
    });

  const officeMarkers = await Promise.all(
    officesModel.filter(filterWithValidCoordinates).map(async ({ elements, system }): Promise<Marker> => {
      const isHeadOffice = !!elements.isHeadOffice.value[0]?.name;
      const position = getPosition(elements.googlePin.value)!;
      const contentPageUrl =
        elements.country.value[0]?.codename &&
        (await getOfficePage(elements.country.value[0]?.codename, languageCodename));

      return {
        city: elements.city.value,
        country: elements.country.value[0].name,
        icon: `/images/googleMap/${isHeadOffice ? 'officeMain' : 'officeLocal'}.png`,
        id: system.id,
        infoWindow: getInfoWindowString({
          address: createRichTextHtmlResolver(nodeParser).resolveRichText({
            element: elements.address,
          }).html,
          phoneNumber: elements.phoneNumber.value,
          position,
          title: elements.officeName.value || `${elements.city.value}, ${elements.country.value[0].name}`,
          url: contentPageUrl && `/${multiple('contactUsUrl')}/${contentPageUrl}`,
          common,
        }),
        position,
        type: isHeadOffice ? 'head' : 'local',
      };
    }),
  );

  const featuredProjectsMarkers = featuredProjectsModel
    .filter(filterWithValidCoordinates)
    .map(({ elements, system }): Marker => {
      const position = getPosition(elements.googlePin.value)!;

      return {
        city: null,
        country: elements.tagsLocation.value[0]?.name ?? null,
        icon: '/images/googleMap/featuredProject.png',
        id: system.id,
        infoWindow: getInfoWindowString({
          description: createRichTextHtmlResolver(nodeParser).resolveRichText({
            element: elements.summaryDescription,
          }).html,
          position,
          title: elements.summaryTitle.value,
          url: `/${elements.url.value}`,
          common,
        }),
        position,
        type: 'project',
      };
    });

  return {
    variants,
    generalOffice: getNormalizedGeneralOfficeData(officesModel),
    markers: [...officeMarkers, ...featuredProjectsMarkers],
    officeOverviewMetadata: officeOverviewMetadata,
    // markers: [],
  };
};

const ContactUsMapPage = (props: Awaited<ReturnType<typeof getStaticMapProps>>) => (
  <MapPage {...props} officeOverviewMetadata={props.officeOverviewMetadata} />
);

export default ContactUsMapPage;
