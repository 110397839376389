import { ProdClient } from 'client/client';
import OfficesPage from 'components/pages/ContactUs/OfficesPage';
import { CountriesWithOffices } from 'components/pages/ContactUs/types';
import { getTranslations } from 'helpers/translations';
import _compact from 'lodash/compact';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import _toPairs from 'lodash/toPairs';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import {
  ContentPage as ContentPageModel,
  Office as OfficeModel,
  OfficeOverviewMetadata,
  OfficePageContent,
} from 'models';
import getNormalizedGeneralOfficeData from 'utils/normalization/generalOffice';
import { getContactUsVariants } from 'utils/overviewVariants';

export const getStaticContactUsProps = async ({ locale }) => {
  const translations = await getTranslations(locale);
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;

  const [
    {
      data: { items: contentPageModel },
    },
    {
      data: { items: officesModel },
    },
  ] = await Promise.all([
    ProdClient.items<ContentPageModel>()
      .type('content_page')
      .containsFilter('elements.category', ['office'])
      .notEmptyFilter('elements.tags__location')
      .languageParameter(languageCodename)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise(),
    ProdClient.items<OfficeModel>()
      .type('office')
      .languageParameter(languageCodename)
      .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
      .toPromise(),
  ]);

  const variants = await getContactUsVariants();

  const officeOverviewMetadata = await ProdClient.items<OfficeOverviewMetadata>()
    .type('office_overview_metadata')
    .languageParameter(languageCodename)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items[0];
    });

  const allCountries = _sortBy(
    contentPageModel.map(({ elements, system }) => {
      if ((elements.content.linkedItems[0] as OfficePageContent).elements.isMainVariant.value[0]?.codename === 'yes')
        return {
          title: elements.urlSlugGenerator.value,
          region: translations.location(elements.summaryOfficePageGroup?.value[0]?.codename),
          url: elements.url.value,
          codename: system.codename,
        };
    }),
    'title',
  ).filter((page) => page) as CountriesWithOffices[];
  return {
    countriesWithOffices: {
      all: allCountries,
      grouped: _sortBy(_toPairs(_groupBy(allCountries, 'region')), '0'),
    },
    generalOffice: getNormalizedGeneralOfficeData(officesModel),
    variants,
    officeOverviewMetadata: officeOverviewMetadata,
  };
};

const ContactUsOfficesPage = (props: Awaited<ReturnType<typeof getStaticContactUsProps>>) => {
  return <OfficesPage {...props} officeOverviewMetadata={props.officeOverviewMetadata} />;
};

export default ContactUsOfficesPage;
