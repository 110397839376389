import { ProdClient } from 'client/client';
import SinglePersonPage from 'components/pages/ContactUs/SinglePersonPage';
//import cacheData from 'memory-cache';
import { Contact, Contact as ContactModel, FormModal } from 'models';
import { getContactUsVariants } from 'utils/overviewVariants';

export const apiElements = [
  'url',
  'display_name',
  'description',
  'job_title',
  'city',
  'department',
  'email',
  'id',
  'frontify_image',
];

export const getStaticContactProps = async ({ urlParam, locale }: { locale: string; urlParam: string }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;
  // eslint-disable-next-line no-console
  const person = await ProdClient.items<ContactModel>()
    .type('contact')
    .languageParameter(languageCodename)
    .limitParameter(1)
    .equalsFilter('elements.id', urlParam)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((res) => res.data.items[0].elements as unknown as Omit<ContactModel['elements'], 'key'>)
    .catch(() => null);
  const form = await ProdClient.item<FormModal>('get_in_touch')
    .languageParameter(languageCodename)
    .toPromise()
    .then((res) => {
      //  console.log("response - get in touch",res.data.item)
      return res.data.item;
    });
  const contact = await ProdClient.items<Contact>()
    .type('contact')
    .languageParameter(languageCodename)
    .limitParameter(1)
    .equalsFilter('elements.id', urlParam)
    .elementsParameter(apiElements)
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((response) => {
      return response.data.items[0];
    });
  // eslint-disable-next-line no-console
  const variants = await getContactUsVariants('peopleUrl');
  return {
    variants,
    person,
    form,
    contact,
  };
};

const ContactUsSinglePersonPage = (props: Awaited<ReturnType<typeof getStaticContactProps>>) => {
  return <SinglePersonPage {...props} contact={props.contact} />;
};

export default ContactUsSinglePersonPage;
