import { Box, Container, Flex, Text, UnorderedList, VisuallyHidden } from '@chakra-ui/react';
import axios from 'axios';
import { ProdClient } from 'client/client';
import Breadcrumb from 'components/base/Breadcrumb';
import { Button } from 'components/base/Button';
import { ShareButton } from 'components/base/ShareButton';
import { Factbox } from 'components/modules/Factbox';
import { MediaContent } from 'components/modules/MediaContent';
import { TalentCommunitiesCTA } from 'components/modules/TalentCommunitiesCTA';
import { TextModule } from 'components/modules/TextModule';
import IdPagesMetaData from 'lib/vacanciesMetaData';
import { TalentCommunity } from 'models/content-types';
import { JobApplicationPage } from 'models/content-types/job_application_page';
import { useRouter } from 'next/router';
import { withTranslations } from 'store/translations';
import { moduleMaxWidth } from 'themes/overrides';
import { fetchAccessToken } from 'utils/fetchAccessToken';
import { getHost } from 'utils/getHost';
import { imageFormatter, imageFormatterSet } from 'utils/imageFormatter';
import { getCareersVariants } from 'utils/overviewVariants';

import fallbackImage from '../../../../lib/fallbackImage.json';
import countryList from './countryList.json';

type JobProps = {
  title: string;
  teaser: string;
  isGraduateCareers: string;
  isRambollCareers: string;
  refId: string;
  applyUrl: string;
  description: string;
  location: string;
  country: string;
  jobPostId: string;
};

interface VacancyPageProps {
  Job: { data: { jobPost: JobProps } };
  JobApplicationModule: JobApplicationPage;
  talentCommunities: TalentCommunity[];
}

export default function VacancyPage({ Job, JobApplicationModule, talentCommunities }: VacancyPageProps) {
  const { aria, common, multiple } = withTranslations();
  let translated = 'careers';
  const router = useRouter();
  switch (router.locale) {
    case 'en':
      translated = 'careers';
      break;
    case 'en-epac':
      translated = 'careers';
      break;
    case 'en-us':
      translated = 'careers';
      break;
    case 'en-gb':
      translated = 'careers';
      break;
    case 'da-dk':
      translated = 'karriere';
      break;
    case 'de-de':
      translated = 'karriere';
      break;
    case 'fi-fi':
      translated = 'ura';
      break;
    case 'no-no':
      translated = 'karriere';
      break;
    case 'sv-se':
      translated = 'karriar';
      break;
  }
  const jobPost = Job.data.jobPost;
  const mediaContentType = countryList[Job.data.jobPost.country.toLowerCase().replaceAll(' ', '_')] || 'media_content';
  const host = getHost();

  const mediaContentName = mediaContentType
    .split('_')
    .map((word, index) => {
      return index !== 0 ? word[0].toUpperCase() + word.substr(1) : word.toLowerCase();
    })
    .join()
    .replaceAll(',', '');

  const mediaContent = JobApplicationModule.elements[mediaContentName].linkedItems[0];
  const factBox = JobApplicationModule.elements.facts.linkedItems[0];
  const textModule = JobApplicationModule.elements.textModule.linkedItems[0];
  const firstParagraph =
    jobPost.description.split('<p>')[1]?.split('</p>')[0].length > 50
      ? jobPost.description.split('<p>')[1]?.split('</p>')[0]
      : jobPost.description.split('<p>')[1]?.split('</p>')[0] +
        '<br />' +
        jobPost.description.split('<p>')[2]?.split('</p>')[0];

  const metaDataImage =
    imageFormatterSet(mediaContent.elements.mediaFrontify.value, 2)
      ?.split(/,\n/)[3]
      .replace(/&format=.*/g, '')
      .trim() ?? imageFormatter(JSON.stringify(fallbackImage)).src;

  const ogImage =
    imageFormatterSet(JobApplicationModule.elements.theOpenGraphProtocolOgImage?.value, 2)
      ?.split(/,\n/)[3]
      .replace(/&format=.*/g, '')
      .trim() ?? imageFormatter(JSON.stringify(fallbackImage)).src;
  const twitterImage =
    imageFormatterSet(JobApplicationModule.elements.twitterMetaTagsTwitterImage?.value, 2)
      ?.split(/,\n/)[3]
      .replace(/&format=.*/g, '')
      .trim() ?? imageFormatter(JSON.stringify(fallbackImage)).src;

  const PageHero = () => {
    return (
      <Box w='100vw' maxW={moduleMaxWidth} mx='auto' bg={'forest.10'}>
        <Container pt='m' px={{ base: 'xs', lg: 'l' }}>
          <Box>
            <Breadcrumb color='mountain.base' />
          </Box>
          <Flex direction={{ base: 'column', lg: 'row' }} py={{ base: 's', lg: 'm' }} gap={{ base: 's', lg: 'l' }}>
            <Text
              w={{ base: '100%', md: '33%' }}
              as='h1'
              textStyle={{ base: 'mobile.h3', lg: 'desktop.h4' }}
              maxWidth={'420px'}
            >
              {jobPost.title}
            </Text>
            {/* <Box w={{ base: '100%', md: '60%' }}>
              <Box
                textStyle={{ base: 'mobile.body.book.s', lg: 'desktop.body.light.l' }}
                sx={{
                  p: {
                    paddingBottom: '20px',
                  },
                  'p:has(strong)': {
                    paddingBottom: '0',
                  },
                  ul: {
                    paddingBottom: '40px',
                    listStyleType: 'circle',
                    listStylePosition: 'inside',
                  },
                  li: {},
                }}
                dangerouslySetInnerHTML={{ __html: firstParagraphTeaser }}
              />
            </Box> */}
          </Flex>
        </Container>
      </Box>
    );
  };
  const url = router.locale == 'en' ? `${host}/${translated}/${jobPost.jobPostId}` : `${host}/${router.locale}/${translated}/${jobPost.jobPostId}`
  return (
    <>
      <IdPagesMetaData
        category={'career'}
        title={jobPost.title}
        description={firstParagraph}
        url={`${url}`}
        image={metaDataImage}
        ogImage={ogImage}
        twitterImage={twitterImage}
      />
      <Box w='100%'>
        <PageHero />
        {/* ------- Share button ------- */}
        <Box
          display='block'
          h={{ base: '84px', lg: '184px' }}
          w='100%'
          position='relative'
          px={{ base: 'xs', lg: 'l' }}
          pb={{ base: 'xs', lg: 's' }}
          pt={{ base: 'xs', lg: 'm' }}
          maxW='1440px'
          margin='auto'
        >
          <Box w='auto' position='absolute' right={{ base: 'xs', lg: 'l' }}>
            <ShareButton />
          </Box>
        </Box>

        {/* ------- Share button END ------- */}
        <Flex
          gap={{ base: 's', lg: 'xl' }}
          justifyContent='space-between'
          flexDirection={{ base: 'column', lg: 'row' }}
          px={{ base: 'xs', lg: 'l' }}
          pb='m'
          maxW='1440px'
          margin='auto'
        >
          {/* ------- Aside description ------- */}
          <Box w={{ base: '100%', lg: '25%' }} as='aside' h={{ base: 'auto', lg: '100%' }}>
            <VisuallyHidden as='h2'>{aria('vacancyDetails')}</VisuallyHidden>
            <UnorderedList
              as='dl'
              position={{ base: 'relative', lg: 'sticky' }}
              top='0'
              left='0'
              margin='0'
              w={{ base: 'auto', lg: '26vw' }}
              listStyleType='none'
            >
              <Box pb='s'>
                <Text as='dt' textStyle='desktop.body.medium.s'>
                  {common('type')}
                </Text>
                <Text as='dd' pt='xxs' textStyle='desktop.body.book.s'>
                  {jobPost.isGraduateCareers === 'Y'
                    ? 'Early careers'
                    : jobPost.isRambollCareers == 'Y'
                    ? 'Experienced'
                    : ''}
                </Text>
              </Box>

              <Box pb='s'>
                <Text as='dt' textStyle='desktop.body.medium.s'>
                  {common('referenceNumber')}
                </Text>
                <Text as='dd' pt='xxs' textStyle='desktop.body.book.s'>
                  {jobPost.refId}
                </Text>
              </Box>

              <Box pb='s'>
                <Text as='dt' textStyle='desktop.body.medium.s'>
                  {common('location')}
                </Text>
                <Text as='dd' pt='xxs' textStyle='desktop.body.book.s'>
                  {jobPost.location}
                </Text>
              </Box>

              <Button size='medium' as='a' href={jobPost.applyUrl}>
                {common('applyNow')}
              </Button>
            </UnorderedList>
          </Box>
          {/* ------- Aside description END ------- */}

          {/* ------- Text block ------- */}
          <Box w={{ base: '100%', lg: '75%' }}>
            <Box
              textStyle={{ base: 'desktop.body.book.s', lg: 'desktop.body.l' }}
              sx={{
                p: {
                  paddingBottom: 'xs',
                },
                'p:has(strong)': {
                  paddingBottom: 'xs',
                },
                strong: {
                  fontWeight: 400,
                  textStyle: { base: 'desktop.body.medium.s', lg: 'desktop.body.medium.l' },
                },
                ul: {
                  paddingBottom: 's',
                  listStyleType: 'disc',
                  paddingLeft: 'xs',
                },
              }}
              dangerouslySetInnerHTML={{ __html: jobPost.description }}
            />
          </Box>
          {/* ------- Text block END ------- */}
        </Flex>

        {/* ------- Additional modules ------- */}
        <Box>
          <MediaContent model={mediaContent} />
        </Box>
        <Box w='100%'>{textModule && <TextModule model={textModule} />}</Box>
        <TalentCommunitiesCTA options={talentCommunities} />
        <Box>
          <Factbox model={factBox} />
        </Box>
        {/* ------- Additional modules END ------- */}
      </Box>
    </>
  );
}

export const getCareerIdProps = async ({ locale, urlParam }: { locale: string; urlParam?: string }) => {
  const languageCodename = locale ?? process.env.NEXT_PUBLIC_DEFAULT_LOCALE!;

  const fetchedAccessToken = await fetchAccessToken(process.env.JOBS_API_ACCESS_TOKEN_FETCH_SCOPE);

  const Job = await axios
    .get(`${process.env.JOBS_API_URL as string}/${urlParam}`, {
      data: {
        FilterConditions: {},
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${fetchedAccessToken.data.access_token}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });

  if (Job === undefined) {
    return {
      redirect: {
        destination: '/error',
      },
      props: {},
    };
  }

  // If elementsParamterer is working this could be added as:
  // ['text_module', 'facts', mediaContentName]
  // const mediaContentName = countryList[Job.data.jobPost.country.toLowerCase().replaceAll(' ', '_')] || 'media_content';

  const JobApplicationModule = await ProdClient.items<JobApplicationPage>()
    .languageParameter(languageCodename)
    .depthParameter(2)
    .type('job_application_page')
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((res) => {
      return res.data.items[0];
    });

  const talentCommunities = await ProdClient.items<TalentCommunity>()
    .languageParameter(languageCodename)

    .type('talent_community')
    .collection(process.env.NEXT_PUBLIC_KONTENT_AI_COLLECTION!)
    .toPromise()
    .then((res) => {
      return res.data.items;
    });

  const variants = await getCareersVariants({ url: Job.data.jobPost.jobPostId });

  return {
    Job: Job,
    variants,
    JobApplicationModule,
    talentCommunities,
  };
};
