import { Card, CardBody, Text } from '@chakra-ui/react';
import { addSeconds, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { withTranslations } from 'store/translations';

type GeneralOfficeProps = {
  city: string;
  country: string;
  officeName: string | null;
  phoneNumber: string;
  timeZone: string;
};

const GeneralOffice = ({ city, country, officeName, phoneNumber, timeZone }: GeneralOfficeProps) => {
  const { common } = withTranslations();
  const [localTime, setLocalTime] = useState<Date>();

  useEffect(() => {
    const localTime = zonedTimeToUtc(new Date(), timeZone);
    setLocalTime(localTime);

    const interval = setInterval(() => {
      setLocalTime((state) => addSeconds(state!, 1));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeZone]);

  return (
    <Card
      bg='white'
      borderRadius='0'
      pl={{ base: '0', lg: 'xs' }}
      pr='l'
      py={{ base: 'xxs', lg: 'xs' }}
      variant='unstyled'
    >
      <CardBody>
        <Text as='h2' color='cyan.web' pb='xs' textStyle={'desktop.body.l'}>
          {city}, {country}
        </Text>
        {officeName && (
          <Text color='mountain.base' textStyle='mobile.body.s'>
            {officeName}
          </Text>
        )}
        <Text color='mountain.base' textStyle='mobile.body.xs'>
          {common('telephoneShort')}:{phoneNumber}
        </Text>
        {localTime && (
          <Text color='mountain.60' textStyle='mobile.body.xs'>
            {common('localTime')}: {format(localTime, 'hh:mm a')}
          </Text>
        )}
      </CardBody>
    </Card>
  );
};

export default GeneralOffice;
