export const alphabeticalSort = (a: string, b: string, indicator?: number) => {
  const one = indicator ? a.split(' ')[indicator] : a;
  const two = indicator ? b.split(' ')[indicator] : b;

  if (one < two) {
    return -1;
  }

  if (one > two) {
    return 1;
  }

  return 0;
};
