import { Box, Button, Flex, Text } from '@chakra-ui/react';

import { IconComp } from './IconComp';

export interface PaginationProps {
  total: number;
  perPage?: number;
  current?: number;
  onNavigate: (page: number) => void;
}

export function PaginationNew({ current = 1, perPage = 10, total, onNavigate }: PaginationProps) {
  const pages = Math.ceil(total / perPage);

  /**
   * Pagination should only show if there is enough content to fill more than one page.
   */
  if (total / perPage <= 1 || !pages || current < 0 || current > pages) return null;

  /**
   *
   * @param toPage new page
   */
  const handleNavigate = (toPage: number) => {
    onNavigate(toPage);
    window.scrollTo(0, 0);
  };

  /**
   * Creates a new array based on a start & end parameter.
   * @param start start position of array
   * @param end end position of array
   */
  const getRestItems = (start: number, end: number): number[] =>
    [...Array(end - start + 1).keys()].map((_v, i) => i + start);

  /**
   * Determine delta
   */
  const getDelta = () => {
    if (pages <= 8) return 8;
    return current > 4 && current < pages - 3 ? 2 : 4;
  };

  /**
   * Adds a [...] item if there is enough content (based on delta)
   * @param items array of items
   */
  const handleDots = (items: (string | number)[]) => {
    const addDots = (value: number, pair: (string | number)[]) => (items.length + 1 !== pages ? pair : [value]);

    if (items[0] !== 1) {
      items = addDots(1, [1, '...']).concat(items);
    }

    if (items[items.length - 1] < pages) {
      items = items.concat(addDots(pages, ['...', pages]));
    }

    return items;
  };

  /**
   * Build up items including
   */
  const getItems = () => {
    const delta = getDelta();

    let start = Math.round(current - delta / 2),
      end = Math.round(current + delta / 2);

    if (start - 1 === 1 || end + 1 === pages) {
      start += 1;
      end += 1;
    }

    const items =
      current > delta
        ? getRestItems(Math.min(start, pages - delta), Math.min(end, pages))
        : getRestItems(1, Math.min(pages, delta + 1));

    const enhancedItems = handleDots(items);

    return enhancedItems;
  };

  return (
    <Box>
      <Flex alignItems='center'>
        <Button
          p={0}
          w='24px'
          h='24px'
          minW='auto'
          isDisabled={current <= 1}
          variant='clear'
          onClick={() => handleNavigate(1)}
        >
          <IconComp
            iconName='doubleChevronLeft'
            styleName={current === 1 ? 'disabled' : 'default'}
            chakraStyle={{
              position: 'initial',
              display: 'block',
              w: '24px',
              h: '24px',
            }}
          />
        </Button>
        <Button
          p={0}
          w='24px'
          h='24px'
          minW='auto'
          isDisabled={current <= 1}
          variant='clear'
          onClick={() => handleNavigate(current - 1)}
        >
          <IconComp
            iconName='chevronLeft'
            styleName={current === 1 ? 'disabled' : 'default'}
            chakraStyle={{
              position: 'initial',
              display: 'block',
              w: '24px',
              h: '24px',
            }}
          />
        </Button>

        <Flex alignItems='center'>
          {getItems().map((item: number | string, index) => (
            <Box key={`pagination-${item}-${index}`} mx='xxs'>
              {typeof item !== 'number' ? (
                <Text textStyle='desktop.body.medium.l' color='mountain.base'>
                  {item}
                </Text>
              ) : (
                <Button
                  w='28px'
                  h='28px'
                  minW='28px'
                  variant='clear'
                  color={current === item ? 'cyan.web' : 'mountain.base'}
                  _hover={{
                    color: 'cyan.web',
                  }}
                  cursor={current === item ? 'not-allowed' : 'pointer'}
                  textStyle={current === item ? 'desktop.body.medium.l' : 'desktop.body.s'}
                  {...(current !== item && { onClick: () => handleNavigate(item) })}
                >
                  {item}
                </Button>
              )}
            </Box>
          ))}
        </Flex>

        <Button
          p={0}
          w='24px'
          h='24px'
          minW='auto'
          isDisabled={current >= pages}
          variant='clear'
          onClick={() => handleNavigate(current + 1)}
        >
          <IconComp
            iconName='chevronRight'
            styleName={current === total ? 'disabled' : 'default'}
            chakraStyle={{
              position: 'initial',
              display: 'block',
              w: '24px',
              h: '24px',
            }}
          />
        </Button>
        <Button
          p={0}
          w='24px'
          h='24px'
          minW='auto'
          isDisabled={current >= pages}
          variant='clear'
          onClick={() => handleNavigate(pages)}
        >
          <IconComp
            iconName='doubleChevronRight'
            styleName={current === total ? 'disabled' : 'default'}
            chakraStyle={{
              position: 'initial',
              display: 'block',
              w: '24px',
              h: '24px',
            }}
          />
        </Button>
      </Flex>
    </Box>
  );
}
