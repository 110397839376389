import { Box, Link, List, ListItem, SimpleGrid, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { withTranslations } from 'store/translations';

import { CountriesWithOfficesCollection } from './types';

type OfficesListProps = {
  countriesWithOffices: CountriesWithOfficesCollection;
};

const OfficesList = ({ countriesWithOffices }: OfficesListProps) => {
  const { multiple } = withTranslations();
  const router = useRouter();
  return (
    <>
      <SimpleGrid
        as='ul'
        columns={{ base: 1, md: 2, lg: 3 }}
        display={{ base: 'none', md: 'grid' }}
        listStyleType='none'
      >
        {countriesWithOffices.all.map(({ title, codename, url }) => (
          <Box key={codename} as='li' mb='m'>
            <Link
              as={'a'}
              color='mountain.base'
              href={`/${router.locale}/${multiple('contactUsUrl')}/${url}`}
              textStyle='desktop.h5'
            >
              {title}
            </Link>
          </Box>
        ))}
      </SimpleGrid>
      <List display={{ base: 'block', md: 'none' }}>
        {countriesWithOffices.grouped.map(([category, group]) => (
          <Fragment key={category}>
            <ListItem color='mountain.80' mb='s'>
              <Text textStyle='mobile.body.s'>{category}</Text>
            </ListItem>
            {group.map(({ title, codename, url }) => (
              <ListItem key={`${category}-${codename}`} mb='s'>
                <Link
                  as={'a'}
                  color='mountain.base'
                  href={`/${router.locale}/${multiple('contactUsUrl')}/${url}`}
                  textStyle={{ base: 'mobile.h4', lg: 'desktop.h5' }}
                >
                  {title}
                </Link>
              </ListItem>
            ))}
          </Fragment>
        ))}
      </List>
    </>
  );
};

export default OfficesList;
