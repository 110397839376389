import { Box, Flex } from '@chakra-ui/react';
import { withTranslations } from 'store/translations';

import { IconComp } from './IconComp';

export type ToggleState = 'list' | 'map';

type ToggleProps = {
  onChange: (toggleState: ToggleState) => void;
  value: ToggleState;
};

const Toggle = ({ onChange, value }: ToggleProps) => {
  const handleOnChange = (state: ToggleState) => () => onChange(state);
  const { common } = withTranslations();
  return (
    <Flex>
      <Flex
        as='button'
        pl='xs'
        pr='xs'
        py='xxs'
        border='1px solid'
        borderColor='mountain.20'
        align='center'
        color={value === 'list' ? 'cyan.web' : 'mountain.base'}
        onClick={handleOnChange('list')}
      >
        <IconComp iconName='list' styleName={value === 'list' ? 'active' : 'default'} />
        <Box as='span' display={{ base: 'none', lg: 'block' }}>
          {common('list')}
        </Box>
      </Flex>
      <Flex
        as='button'
        pl='xs'
        pr='xs'
        py='xxs'
        border='1px solid'
        borderLeft={'none'}
        borderColor='mountain.20'
        align='center'
        color={value === 'map' ? 'cyan.web' : 'mountain.base'}
        onClick={handleOnChange('map')}
      >
        <IconComp iconName='map' styleName={value === 'map' ? 'active' : 'default'} />
        <Box as='span' display={{ base: 'none', lg: 'block' }}>
          {common('map')}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Toggle;
